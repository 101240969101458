<script>
    import { MapDefault } from '../classes/MapDefault';
    import { Eventbooths } from '../classes/Eventbooths';

    export default {
        props: {
            res_mapdata: Object,
            res_geojson: String,
            res_eventbooths: Object,
            res_paths: Object
        },
        data() {
            return {
                eventboothsData: new Eventbooths(this.res_eventbooths),
                mapData: new MapDefault(this.res_mapdata),
                geoJsonData: JSON.parse(this.res_geojson),
                paths: this.res_paths,
            }
        },
        computed: {
            options() {
                return {
                    onEachFeature: this.onEachFeatureFunction
                }
            },
            getColor() {
                return (color, from) => {
                    if (from == 0) {
                        return color == undefined ? 'transparent': color;
                    } else {
                        return color == undefined ? '#7F1DC3': color;
                    }
                }
            },
            styleFloorFunction() {
                return (feature) => {
                    return {
                        fillColor: this.getColor(feature.geometry.colorSelected, 0),
                        weight: 2,
                        opacity: 1,
                        color: 'transparent',
                        dashArray: '3',
                        fillOpacity: 0.3
                    }
                }
            },
            styleHighlightFunction() {
                return () => {
                    return {
                        fillColor: 'transparent',
                        weight: 2,
                        opacity: 1,
                        color: 'transparent',
                        dashArray: '3',
                        fillOpacity: 0
                    }
                }

            },
            highlightFeature() {
                return (e) => {
                    var layer = e.target;

                    layer.setStyle({
                        fillColor: '#d3d3d3',
                        weight: 5,
                        color: 'transparent',
                        dashArray: '',
                        fillOpacity: 0.5
                    });

                    layer.bringToFront();
                }
            },
            resetHighlight() {
                return (e) => {
                    var layer = e.target;

                    layer.setStyle({
                        fillColor: 'transparent',
                        weight: 2,
                        opacity: 1,
                        color: 'transparent',
                        dashArray: '3',
                        fillOpacity: 0
                    });

                    layer.bringToFront();
                }
            },
            getFeatureData() {
                return (e) => {
                    console.log(e)
                }
            },
            onEachFeatureFunction() {
                return (feature, layer) => {
                    layer.on({
                        mouseover: this.highlightFeature,
                        mouseout: this.resetHighlight,
                        click: this.getFeatureData
                    })
                }
            }
        }
    }
</script>

<template>
    <div style="height:100vh; width:100vw">
        <l-map ref="map" :use-global-leaflet="false" v-model:zoom="mapData.mapZoom" :center="mapData.mapCenter">

            <l-tile-layer
                url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                layer-type="base"
                :maxZoom="mapData.maxZoom"
                :subdomains="mapData.subdomains"
            ></l-tile-layer>
            
            <l-wms-tile-layer
                url="https://mapas01.geolivre.com.br/cgi-bin/mapserv?map=/maps/maps-ev/mapa01-jeri-v08.map"
                layers="TodasCamadas,TodasCamadas2"
                :transparent=true
                :maxZoom="mapData.maxZoom"
                format="image/png"
            ></l-wms-tile-layer>

            <l-geo-json 
                :geojson="geoJsonData" 
                :options-style="styleFloorFunction" 
            ></l-geo-json>

            <l-wms-tile-layer
                v-for="eb_default in eventboothsData.default"
                v-model:url="paths.eventboothDefault"
                :layers="eb_default"
                :transparent=true
                format="image/png"
                version="1.3"
                :uppercase=true
                :crossOrigin=false
                maxZoom:22
            ></l-wms-tile-layer>

            <l-wms-tile-layer
                v-for="custom in eventboothsData.custom"
                :url="custom.url"
                :layers="custom.layer"
                :transparent=true
                format="image/png"
                version="1.3"
                :uppercase=true
                :crossOrigin=false
                maxZoom:22
            ></l-wms-tile-layer>

            <l-geo-json 
                :geojson="geoJsonData" 
                :options-style="styleHighlightFunction" 
                :options="options"
                :interactive=false 
            ></l-geo-json>

            <l-wms-tile-layer
                :url="paths.totemColorURL"
                layers="big_totems,small_totems"
                :transparent=true
                :maxZoom="mapData.maxZoom"
                format="image/png"
                version="1.3"
                :uppercase=true
                :crossOrigin=false
            ></l-wms-tile-layer>

            <l-wms-tile-layer
                :url="paths.totemURL"
                layers="t02,t04,t06,t14,t18,t19,t20,t22,t24"
                :transparent=true
                :maxZoom="mapData.maxZoom"
                format="image/png"
                version="1.3"
                :uppercase=true
                :crossOrigin=false
            ></l-wms-tile-layer>

        </l-map>
    </div>
</template>
