<script setup>
	import { onMounted, inject, ref } from "vue";
	import { usePage, router } from '@inertiajs/vue3';

    onMounted(() => {
        window.close()
    })
</script>

<template>
</template>

<style scoped>
</style>
