<script setup>
	import { onMounted, onBeforeMount, provide, ref } from "vue";
	import { usePage, router } from '@inertiajs/vue3'

	import Navbar from '../../components/Navbar.vue';
	import ErrorBar from '../../components/ErrorBar.vue';
	import EventactivityCard from '../../components/EventactivityCard.vue'

	const page = usePage()

	onMounted(() => {
		window.addEventListener("pageshow", function(event) {
		var historyTraversal = event.persisted ||
				 (typeof window.performance != "undefined" &&
				  window.performance.navigation.type === 2);
		  if (historyTraversal) {
			router.reload({only: ['auth']})
		  }
		});
	})
</script>

<template>
	<Navbar />
	<ErrorBar />

	<div id="content">
			<h1 style="color: #414BB2; margin-left: 10px; margin-top: 10px;">Minhas Atividades</h1>
			<EventactivityCard v-for="subscription in page.props.subscriptions" :subscription="subscription" />
	</div>
</template>

<style scoped>
</style>
