<script setup>
    import { MapDefault } from '../classes/MapDefault';
    import { Eventbooths } from '../classes/Eventbooths';
    import { LayerStyle } from '../functions/LayerStyle';
    import { reactive, onMounted } from 'vue';
    // MODALS
    import { Modal } from 'bootstrap';
    import MainStageModal from '../components/modals/MainStageModal.vue';
    import UnlockFeaturesModal from '../components/modals/UnlockFeaturesModal.vue';
    import SpaceModal from '../components/modals/SpaceModal.vue';
    import NetworkingModal from '../components/modals/NetworkingModal.vue';

    const props = defineProps({
        mapdata: new MapDefault(),
        geojson: Object,
        eventbooths: new Eventbooths(),
        paths: Object,
    })

    const state = reactive({
        main_stage_modal: null,
        networking_modal: null,
        unlock_features_modal: null,
        space_modal: null
    })

    onMounted(() => {
        state.main_stage_modal = new Modal('#main_stage_modal', {})
        // state.networking_modal = new Modal('#networking_modal', {})
        // state.unlock_features_modal = new Modal('#unlock_features_modal', {})
        // state.space_modal = new Modal('#space_modal', {})
    })

    function openModal(modal)
    {
        modal.show()
    }

    function closeModal(modal)
    {
        modal.hide()
    }

    const layerStyle = new LayerStyle()
    const styleFloorFunction = (feature) => { return layerStyle.floor(feature) }
    const styleHighlightFunction = () => { return layerStyle.highlight() }

</script>

<script>
    export default {
        data() {
            return {
                initialMapData: new MapDefault(this.mapdata)
            }
        },
        computed: {
            geojsonOptions() {
                return {
                    onEachFeature: this.onEachFeatureFunction
                }
            },
            highlightFeature() {
                return (e) => {
                    var layer = e.target;

                    layer.setStyle({
                        fillColor: '#d3d3d3',
                        weight: 5,
                        color: 'transparent',
                        dashArray: '',
                        fillOpacity: 0.5
                    });

                    layer.bringToFront();
                }
            },
            resetHighlight() {
                return (e) => {
                    var layer = e.target;

                    layer.setStyle({
                        fillColor: 'transparent',
                        weight: 2,
                        opacity: 1,
                        color: 'transparent',
                        dashArray: '3',
                        fillOpacity: 0
                    });

                    layer.bringToFront();
                }
            },
            getFeatureData() {
                return (e) => {
                    console.log("oii")
                }
            },
            onEachFeatureFunction() {
                return (feature, layer) => {
                    layer.on({
                        mouseover: this.highlightFeature,
                        mouseout: this.resetHighlight,
                        click: this.getFeatureData
                    })
                }
            },
            centerMap() {
                // this.mapdata.mapCenter = this.initialMapData.mapCenter
                // this.mapdata.mapZoom = this.initialMapData.mapZoom
                // this.mapdata.mapCenter = [-2.79234, -40.524294]
            }
        }
    }
</script>

<template>
    <MainStageModal :closeModal="closeModal" :modal="state.main_stage_modal" />
    <!-- <UnlockFeaturesModal :closeModal="closeModal" />
    <SpaceModal :closeModal="closeModal" />
    <NetworkingModal :closeModal="closeModal" /> -->

    <!-- <button class="btn btn-success" @click="openModal">Teste</button> -->

    <div style="height:100vh; width:100vw">

        <l-map ref="map" :use-global-leaflet="false" v-model:zoom="mapdata.mapZoom" v-model:center="mapdata.mapCenter" :options="{attributionControl: false}">

            <l-tile-layer
                url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                layer-type="base"
                :maxZoom="mapdata.maxZoom"
                :subdomains="mapdata.subdomains"
            ></l-tile-layer>

            <l-control position="topleft" class="leaflet-control-zoom leaflet-bar leaflet-control">
                <button @click="centerMap">
                    <font-awesome-icon icon="fa-regular fa-compass" />
                </button>
            </l-control>

            <l-control-attribution position="bottomleft" prefix="<strong>Eventoverso</strong>"/>

            <l-wms-tile-layer
                url="https://mapas01.geolivre.com.br/cgi-bin/mapserv?map=/maps/maps-ev/mapa01-jeri-v08.map"
                layers="TodasCamadas,TodasCamadas2"
                :transparent=true
                :maxZoom="mapdata.maxZoom"
                format="image/png"
            ></l-wms-tile-layer>

            <l-geo-json
                :geojson="geojson"
                :options-style="styleFloorFunction"
            ></l-geo-json>

            <l-wms-tile-layer
                v-for="eb_default in eventbooths.default"
                v-model:url="paths.eventboothDefault"
                :layers="eb_default"
                :transparent=true
                format="image/png"
                version="1.3"
                :uppercase=true
                :crossOrigin=false
                maxZoom:22
            ></l-wms-tile-layer>

            <l-wms-tile-layer
                v-for="custom in eventbooths.custom"
                :url="custom.url"
                :layers="custom.layer"
                :transparent=true
                format="image/png"
                version="1.3"
                :uppercase=true
                :crossOrigin=false
                maxZoom:22
            ></l-wms-tile-layer>


            <l-geo-json
                :geojson="geojson"
                :options-style="styleHighlightFunction"
                :options="geojsonOptions"
                :interactive=false
            ></l-geo-json>


            <l-wms-tile-layer
                :url="paths.totemColorURL"
                layers="big_totems,small_totems"
                :transparent=true
                :maxZoom="mapdata.maxZoom"
                format="image/png"
                version="1.3"
                :uppercase=true
                :crossOrigin=false
            ></l-wms-tile-layer>

            <l-wms-tile-layer
                :url="paths.totemURL"
                layers="t02,t04,t06,t14,t18,t19,t20,t22,t24"
                :transparent=true
                :maxZoom="mapdata.maxZoom"
                format="image/png"
                version="1.3"
                :uppercase=true
                :crossOrigin=false
            ></l-wms-tile-layer>

        </l-map>

    </div>
</template>
