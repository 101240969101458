<script setup>
	import { onMounted, defineProps, onBeforeMount, provide, ref } from "vue";
	import { usePage, router } from '@inertiajs/vue3'
	import Paginate from "vuejs-paginate-next";

	const page = usePage()

	const props = defineProps({
		subscription: Object,
	})


	const multiverseRoute = route('ev.multiverse.view', {id: props.subscription.event.multiverse_id})

	let dateBRShort = new Intl.DateTimeFormat('pt-BR', {
		month: '2-digit',
		day: '2-digit',
		timeZone: 'America/Sao_Paulo',
	})
	let dateBRMonth = new Intl.DateTimeFormat('pt-BR', {
		month: '2-digit',
		timeZone: 'America/Sao_Paulo',
	})
	let dateBRDay = new Intl.DateTimeFormat('pt-BR', {
		day: '2-digit',
		timeZone: 'America/Sao_Paulo',
	})
	let dateBRWeekday = new Intl.DateTimeFormat('pt-BR', {
		weekday: "short",
		timeZone: 'America/Sao_Paulo',
	})
	let dateBRHours = new Intl.DateTimeFormat('pt-BR', {
		hour: '2-digit',
		minute: '2-digit',
		timeZone: 'America/Sao_Paulo',
	})

	const itemsPerPage = 5
	const pageNumber = ref(1)

	const minIdRange = () => {
		return 0 + itemsPerPage * (pageNumber.value - 1)
	}
	const maxIdRange = () => {
		return (itemsPerPage - 1) + itemsPerPage * (pageNumber.value - 1)
	}

	const monthNumber = ref(parseInt(dateBRMonth.format(new Date(props.subscription.event.eventactivities[0].start_date_time))))

	const toggleActivities = (buttonContainerId, subscriptionId) => {
		let subscriptionContainer = document.querySelector(`#subscription-${subscriptionId}`)

		let activityContainer = subscriptionContainer.querySelector('.menus-container')
		let activityContainerStyle = window.getComputedStyle(activityContainer)
		let collapseButton = subscriptionContainer.querySelector(`#${buttonContainerId}`)
		let collapsing = true

		if (activityContainerStyle.display == "none") {
			activityContainer.style.display = "flex"
			collapsing = false
		}

		activityContainer.classList.toggle('collapsed-activity')
		collapseButton.classList.toggle('rotate-button')

		if (collapsing) {
			setTimeout(() => {
				activityContainer.style.display = 'none'
			}, parseFloat(activityContainerStyle.transition) * 1000)

		}
	}

	const toggleCalendar = (buttonContainerId, subscriptionId) => {
		let subscriptionContainer = document.querySelector(`#subscription-${subscriptionId}`)

		let activityContainer = subscriptionContainer.querySelector('.activity-container')
		let activityContainerStyle = window.getComputedStyle(activityContainer)

		let calendarContainer = subscriptionContainer.querySelector('.calendar_and_filter_container')
		let calendarContainerStyle = window.getComputedStyle(calendarContainer)

		let collapseButton = subscriptionContainer.querySelector(`#${buttonContainerId}`)
		let activityCollapsing = true
		let calendarCollapsing = true

		if (activityContainerStyle.display == "none") {
			activityContainer.style.display = "flex"
			activityCollapsing = false
		}

		if (calendarContainerStyle.display == "none") {
			calendarContainer.style.display = "flex"
			calendarCollapsing = false
		}

		activityContainer.classList.toggle('collapsed-activity')
		calendarContainer.classList.toggle('collapsed-activity')

		if (calendarCollapsing) {
			setTimeout(() => {
				calendarContainer.style.display = 'none'
			}, parseFloat(calendarContainerStyle.transition) * 1000)

		}

		if (activityCollapsing) {
			setTimeout(() => {
				activityContainer.style.display = 'none'
			}, parseFloat(activityContainerStyle.transition) * 1000)

		}
	}

	const generateMonthDays = (year, month) => {

		let daysInMonth = new Date(year, month, 0).getDate()
		let startingDayString = dateBRWeekday.format(new Date(`${year} ${month} 01`))
		let startingDayIndex;

		if (startingDayString == 'dom.') {
			startingDayIndex = 0
		}
		if (startingDayString == 'seg.') {
			startingDayIndex = 1
		}
		if (startingDayString == 'ter.') {
			startingDayIndex = 2
		}
		if (startingDayString == 'qua.') {
			startingDayIndex = 3
		}
		if (startingDayString == 'qui.') {
			startingDayIndex = 4
		}
		if (startingDayString == 'sex.') {
			startingDayIndex = 5
		}
		if (startingDayString == 'sáb.') {
			startingDayIndex = 6
		}

		let daysArray = []

		for (let i = 1; i <= daysInMonth;) {
			if (startingDayIndex > 0) {
				daysArray.push({
					day_number: 0,
					weekday: 1
				});
				startingDayIndex--
				continue
			}

			let is_weekday = 0;

			if (dateBRWeekday.format(new Date(`${year} ${month} ${i}`)) != 'sáb.' && dateBRWeekday.format(new Date(`${year} ${month} ${i}`)) != 'dom.') {
				is_weekday = 1
			}

			daysArray.push({
				day_number: i,
				weekday: is_weekday
			})
			i++

		}

		return daysArray

	}

	let random_color_map = [
		{act_id: null, color: 'D96666'}, {act_id: null, color: 'E67399'}, {act_id: null, color: 'B373B3'},
		{act_id: null, color: '8C66D9'}, {act_id: null, color: '668CB3'}, {act_id: null, color: '668CD9'},
		{act_id: null, color: '59BFB3'}, {act_id: null, color: '65AD89'}, {act_id: null, color: '4CB052'},
		{act_id: null, color: '8CBF40'}, {act_id: null, color: 'BFBF4D'}, {act_id: null, color: 'E0C240'},
		{act_id: null, color: 'F2A640'}, {act_id: null, color: 'E6804D'}, {act_id: null, color: 'BE9494'},
		{act_id: null, color: 'A992A9'}, {act_id: null, color: '8997A5'}, {act_id: null, color: '94A2BE'},
		{act_id: null, color: '85AAA5'}, {act_id: null, color: 'A7A77D'}, {act_id: null, color: 'C4A883'},
		{act_id: null, color: 'C7561E'}, {act_id: null, color: 'B5515D'}, {act_id: null, color: 'C244AB'},
		{act_id: null, color: '603F99'}, {act_id: null, color: '536CA6'}, {act_id: null, color: '3640AD'},
		{act_id: null, color: '3C995B'}, {act_id: null, color: '5CA632'}, {act_id: null, color: '7EC225'},
		{act_id: null, color: 'A7B828'}, {act_id: null, color: 'CF9911'}, {act_id: null, color: 'D47F1E'},
		{act_id: null, color: 'B56414'}, {act_id: null, color: '914D14'}, {act_id: null, color: 'AB2671'},
		{act_id: null, color: '9643A5'}, {act_id: null, color: '4585A3'}, {act_id: null, color: '737373'},
		{act_id: null, color: '41A587'}, {act_id: null, color: 'D1BC36'}, {act_id: null, color: 'AD2D2D'}
	]

	const getDayActivities = (month, day) => {
		let dayActivities = []

		props.subscription.event.eventactivities.forEach(activity => {
			let has_color_assigned = false
			let needs_color_if_unassigned;
			let random_color;

			random_color_map.forEach(color => {
				if (color.act_id == activity.id_eventactivity) {
					has_color_assigned = true
					random_color = color.color
				}
			})

			if (!has_color_assigned) {
				needs_color_if_unassigned = true
				random_color_map.forEach(color => {
					if (color.act_id == null && needs_color_if_unassigned) {
						needs_color_if_unassigned = false
						color.act_id = activity.id_eventactivity
						random_color = color.color
					}
				})
			}

			let activityObj = {
				name: activity.name,
				activity_start: dateBRDay.format(new Date(activity.start_date_time)) == day ? 1 : 0,
				activity_middle: (day >= dateBRDay.format(new Date(activity.start_date_time)) && day <= dateBRDay.format(new Date(activity.end_date_time))) ? 1 : 0,
				activity_end: dateBRDay.format(new Date(activity.end_date_time)) == day ? 1 : 0,
				activity_format_start_date: dateBRHours.format(new Date(activity.start_date_time)),
				activity_format_end_date: dateBRHours.format(new Date(activity.end_date_time)),
				random_color: `#${random_color}`
			}


			let activity_in_month = month >= dateBRMonth.format(new Date(activity.start_date_time)) && month <= dateBRMonth.format(new Date(activity.end_date_time))
			let activity_in_day = day >= dateBRDay.format(new Date(activity.start_date_time)) && day <= dateBRDay.format(new Date(activity.end_date_time))

			if (activity_in_month && activity_in_day) {
				dayActivities.push(activityObj)
			}
		})

		return dayActivities

	}

</script>

<template>
	<div class="container subscription-container" :id="`subscription-${subscription.id_subscription}`">
	  <div class="row subscription-header">
	    <div class="col-10 subscription-title">
	      <p>{{subscription.event.event_name}}</p>
	    </div>
	    <div class="col-2 multiverse-link">
		<a :href="multiverseRoute">Participar</a>

		    <div class="collapse-button-container" id="toggleCal">
			    <div class="collapse-button" @click="toggleCalendar('toggleCal', subscription.id_subscription)">
				<i class="bi bi-calendar2-range-fill"></i>
			    </div>
		    </div>
		    <div class="collapse-button-container" id="toggleAct">
			    <div class="collapse-button" @click="toggleActivities('toggleAct', subscription.id_subscription)">
				<i class="bi bi-caret-down-fill"></i>
			    </div>
		    </div>
	    </div>
	  </div>
	  <div class="activity-boundary">
		<div class="menus-container">
			  <div class="calendar_and_filter_container collapsed-activity" style="display: none;" :page_number="monthNumber">

				<div class="calendar_year_month_mark">{{new Date(Date.now()).getFullYear()}}/{{monthNumber}}</div>
				  <div class="calendar-container" >
					<div class="calendar_day_mark"><p class="calendar_weekend_title">D</p></div>
					<div class="calendar_day_mark"><p class="calendar_weekday_title">S</p></div>
					<div class="calendar_day_mark"><p class="calendar_weekday_title">T</p></div>
					<div class="calendar_day_mark"><p class="calendar_weekday_title">Q</p></div>
					<div class="calendar_day_mark"><p class="calendar_weekday_title">Q</p></div>
					<div class="calendar_day_mark"><p class="calendar_weekday_title">S</p></div>
					<div class="calendar_day_mark"><p class="calendar_weekend_title">S</p></div>

					<div v-for="day in generateMonthDays(new Date(Date.now()).getFullYear(), monthNumber)" class="calendar_day_container">
						<div role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" class="calendar_day_number calendar_weekday" v-if="day.day_number != 0 && day.weekday == 1">
							<p class="calendar_weekday_title">{{day.day_number}}</p>

							<div class="activities_container">
							  <div class="activity_container" v-for="activity in getDayActivities(monthNumber, day.day_number)" :teste="activity.random_color">
								<p :style="`background-color: ${activity.random_color};`"  class="eventactivity_title start_act middle_act end_act" v-if="activity.activity_start == 1 && activity.activity_middle == 1 && activity.activity_end == 1">{{activity.name}}</p>
								<p :style="`background-color: ${activity.random_color};`" class="eventactivity_title start_act middle_act" v-if="activity.activity_start == 1 && activity.activity_middle == 1 && activity.activity_end == 0">{{activity.name}}</p>
								<p :style="`background-color: ${activity.random_color};`" class="eventactivity_title middle_act end_act" v-if="activity.activity_start == 0 && activity.activity_middle == 1 && activity.activity_end == 1">{{activity.name}}</p>
								<p :style="`background-color: ${activity.random_color};`" class="eventactivity_title middle_act" v-if="activity.activity_start == 0 && activity.activity_middle == 1 && activity.activity_end == 0">{{activity.name}}</p>
							  </div>
							</div>

						</div>

						<div role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" class="calendar_day_number calendar_weekend" v-if="day.day_number != 0 && day.weekday == 0">
							<p class="calendar_weekend_title">{{day.day_number}}</p>

							<div class="activities_container">
							  <div class="activity_container" v-for="activity in getDayActivities(monthNumber, day.day_number)">
								<p :style="`background-color: ${activity.random_color};`" class="eventactivity_title start_act middle_act end_act" v-if="activity.activity_start == 1 && activity.activity_middle == 1 && activity.activity_end == 1">{{activity.name}}</p>
								<p :style="`background-color: ${activity.random_color};`" class="eventactivity_title start_act middle_act" v-if="activity.activity_start == 1 && activity.activity_middle == 1 && activity.activity_end == 0">{{activity.name}}</p>
								<p :style="`background-color: ${activity.random_color};`" class="eventactivity_title middle_act end_act" v-if="activity.activity_start == 0 && activity.activity_middle == 1 && activity.activity_end == 1">{{activity.name}}</p>
								<p :style="`background-color: ${activity.random_color};`" class="eventactivity_title middle_act" v-if="activity.activity_start == 0 && activity.activity_middle == 1 && activity.activity_end == 0">{{activity.name}}</p>
							  </div>
							</div>
						</div>

						<div role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" class="calendar_day_number calendar_non_day" v-if="day.day_number == 0"><p>...</p></div>
							<div class="dropdown">
							  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
								<li v-if="getDayActivities(monthNumber, day.day_number).length == 0">
									<a class="dropdown-item" href="#">
										<div class="dropdown_activity_container">
											<div class="dropdown_title_bar_container">
												<div class="dropdown_activity_bar" style="background-color: grey;"></div><p>Nenhuma atividade encontrada :(</p>
											</div>

											<div class="hours_container">
												<p class="hour_start">00:00</p>
												<p class="hour_end">00:00</p>

											</div>
										</div>
									</a>
								</li>
							   	<li v-for="activity in getDayActivities(monthNumber, day.day_number)">
									<a class="dropdown-item" href="#">
										<div class="dropdown_activity_container">
											<div class="dropdown_title_bar_container">
												<div class="dropdown_activity_bar" :style="`background-color: ${activity.random_color};`"></div><p class="activity_bar_title">{{activity.name}}</p>
											</div>

											<div class="hours_container">
												<p class="hour_start">{{activity.activity_format_start_date}}</p>
												<p class="hour_end">{{activity.activity_format_end_date}}</p>

											</div>
										</div>
									</a>
								</li>
							  </ul>
							</div>
					</div>

				  </div>
					<paginate
						v-model="monthNumber"
						:page-count="12"
						:initial-page="parseInt(dateBRMonth.format(new Date(props.subscription.event.eventactivities[0].start_date_time)))"
						:page-range="3"
						:margin-pages="2"
						:prev-text="''"
						:next-text="''"
						container-class="activity-paginator"
						page-class="activity-page"
						page-link-class="activity-link-page"
						prev-class="activity-prev"
						prev-link-class="activity-link-prev"
						next-class="activity-next"
						next-link-class="activity-link-next"
					>
					</paginate>
			  </div>
			<div class="activity-container">
				  <div class="row activity-header">
				    <div class="col">
					Atividade
				    </div>
				    <div class="col">
					Data
				    </div>
				    <div class="col">
					Horário
				    </div>
				    <div class="col">
					Status
				    </div>
				  </div>
				  <div class="row activity-body-container" v-for="activity, id in subscription.event.eventactivities">
				    <div class="col activity-body" v-if="minIdRange() <= id && id <= maxIdRange()">
					    <div class="col">
						{{activity.name}}
					    </div>
					    <div class="col">
						{{dateBRShort.format(new Date(activity.start_date_time))}}
					    </div>
					    <div class="col">
						{{dateBRHours.format(new Date(activity.start_date_time))}} - {{dateBRHours.format(new Date(activity.end_date_time))}}
					    </div>
					    <div class="col status andamento" v-if="activity.status[0] == 'Em andamento'">
						<p>Em andamento</p>
					    </div>
					    <div class="col status breve" v-if="activity.status[0] == 'Em breve'">
						<p>Em breve</p>
					    </div>
					    <div class="col status encerrado" v-if="activity.status[0] == 'Encerrado'">
						<p>Encerrado</p>
					    </div>
				    </div>
				  </div>
				<paginate
					v-model="pageNumber"
					:page-count="subscription.event.eventactivities.length / itemsPerPage"
					:page-range="3"
					:margin-pages="2"
					:prev-text="''"
					:next-text="''"
					container-class="activity-paginator"
					page-class="activity-page"
					page-link-class="activity-link-page"
					prev-class="activity-prev"
					prev-link-class="activity-link-prev"
					next-class="activity-next"
					next-link-class="activity-link-next"
				>
				</paginate>
			</div>
		</div>
	  </div>

	</div>
</template>

<style>
	.subscription-container {
		max-width: 100% !important;
		margin: 0px 10px 20px 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.subscription-header {
		width: 100%;
		background-color: #8d93d1;
		border-radius: 10px;
		padding: 20px;
		color: white;
	}

	.subscription-header .subscription-title {
		align-content: center;
	}

	.subscription-header p {
		margin: 0;
		padding: 0;
		font-weight: bold;
		font-size: 20px;
	}

	.multiverse-link {
		display: flex;
		align-items: center;
		justify-content: end;
	}

	.multiverse-link a {
		background-color: #414BB2;
		padding: 8px 15px 8px 15px;
		border-radius: 10px;
		font-size: 12px;
		margin-right: 10px;
	}

	.activity-body-container {
		width: 100%;
	}

	.activity-header, .activity-body {
		text-align: center;
		width: 100%;
	}

	.activity-header {
		background-color: #e9ecef;
		padding: 10px;
		border-radius: 10px 10px 0px 0px;
		color: #495057;
		font-weight: bold;
	}

	.activity-body {
		border-bottom: 1px solid #dee2e6;
		padding: 10px;
		display: flex;
	}

	.status {
		display: flex;
		justify-content: center;
	}

	.status p {
		margin: 0;
		background-color: #808080;
		border-radius: 10px;
		color: white;
		padding: 2px;
		width: 50%;
		font-size: 15px;
	}

	.pagination {
		margin-top: 30px;
		margin-bottom: 0px;
	}

	.collapse-button {
		background-color: #71757A;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.5s ease-in;
	}

	.collapse-button-container {
		display: flex;
		margin-right: 5px;
		justify-content: center;
		align-items: center;
	}

	.collapsed-activity {
		transform: scale(0);
	}

	.rotate-button {
		transform: rotate(180deg);
	}

	.activity-boundary {
		width: 100%;
		height: fit-content;
		overflow: hidden;
	}

	.activity-container {
		width: 100%;
		animation: translate_down 0.5s ease-in 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: 0.5s ease-in;
	}

	.activity-prev, .activity-link-prev {
		height: 20px;
		width: 20px;
		display: block;
	}

	.activity-link-prev::before {
		display: inline-block;
		margin-left: .255em;
		vertical-align: .255em;
		content: "";
		border-top: .3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
		color: #8d93d1;
		transform: rotate(90deg) scale(3);
		transition: 0.5s linear;
	}

	.activity-next, .activity-link-next {
		height: 20px;
		width: 20px;
		display: block;
	}

	.activity-link-prev, .activity-link-next {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
	}

	.activity-link-next::before {
		display: inline-block;
		content: "";
		border-top: .3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
		color: #8d93d1;
		transform: rotate(-90deg) scale(3);
		transition: 0.5s linear;
	}

	.activity-page, .activity-link-page {
		display: block;
		border-radius: 50%;
		height: 20px;
		width: 20px;
		color: #8d93d1;
	}

	.activity-link-page {
		border: 1px solid #8d93d1;
		transition: 0.5s linear;
		padding: 0;
		margin: 0;
		font-size: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.disabled .activity-link-prev::before, .disabled .activity-link-next::before {
		color: #414BB2 !important;
	}

	.active {
		color: white !important;
	}

	.active .activity-link-page {
		background-color: #8d93d1;
	}

	.activity-paginator {
		display: flex;
		width: 20%;
		justify-content: center;
		align-content: center;
		padding: 10px;
		align-items: center;
		height: fit-content;
		margin-top: 10px;
		margin-bottom: 0px;
	}

	.activity-paginator li {
		margin-left: 10px;
		cursor: pointer;
	}

	.menus-container {
		width: 100%;
		animation: translate_down 0.5s ease-in 1;
		transition: 0.5s ease-in;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.calendar_and_filter_container {
		width: 100%;
		animation: translate_down 0.5s ease-in 1;
		align-items: center;
		transition: 0.5s ease-in;
		height: fit-content;
		display: flex;
		flex-direction: column;

	}

	.calendar-container {
		width: 100%;
		animation: translate_down 0.5s ease-in 1;
		display: grid;
		align-items: center;
		transition: 0.5s ease-in;
		height: fit-content;
		grid-template-columns: 10% 16% 16% 16% 16% 16% 10%;
		grid-template-rows: 30px 150px 150px 150px 150px 150px;
		background-color: #eaeaea;
		border-bottom: 2px dashed #a4a0a0;
		border-left: 2px dashed #a4a0a0;
		border-right: 2px dashed #a4a0a0;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;

	}

	.calendar_day_container {
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		border: 1px solid #d9d9d9;
        display: flex;
	}

	.calendar_day_mark {
		text-align: center;
		height: 100%;
		width: 100%;
		display: flex;
		background-color: #d2d2d2;
		justify-content: center;
		align-items: center;
	}

	.calendar_day_number {
		text-align: end;
		align-content: start;
		width: 100%;
		height: 100%;
	}

	.calendar_day_mark p {
		margin: 0 !important;
	}

	.calendar_day_number p {
		margin: 10px 10px 0px 0px;
	}

	.calendar_weekday, .calendar_weekend {
		background-color: white;
	}

	.calendar_non_day {
		background-color: white;
		color: grey;
	}

	.calendar_year_month_mark {
		width: 100%;
		text-align: center;
		margin-top: 10px;
		background-color: #d2d2d2;
		border-top: 2px dashed #a4a0a0;
		border-left: 2px dashed #a4a0a0;
		border-right: 2px dashed #a4a0a0;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		font-size: 30px;
		color: #414bb2;
		font-weight: bold;
	}

	.activities_container {
		height: 80px;
		margin-top: 10px;
		width: 100%;
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
	}

	.activity_container {
		height: 20px;
		margin: 2.5px 0px 2.5px 0px;
	}

	.activity_container div {
		height: 100%;
	}

	.activity_container p {
		height: 100%;
		text-align: start;
		font-size: 15px;
		display: flex;
		align-items: center;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding: 10px 5px 10px 5px;
		margin: 0;
		font-weight: bold;
	}

	.start_act {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		margin-left: 5px !important;
	}

	.middle_act {

	}

	.end_act {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		margin-right: 5px !important;
	}

	.dropdown-menu {
		width: fit-content;

	}

	.dropdown_activity_container {
		display: flex;
		height: 40px;
		justify-content: space-between;
		animation: translate_down 0.5s ease-in 1;

	}

	.dropdown_title_bar_container {
		display: flex;
		align-items: center;
        margin-right: 50px;
	}

	.dropdown_title_bar_container p {
		margin: 0 !important;
	}

	.dropdown_activity_bar {
		width: 5px;
		height: 100%;
		position: relative;
		margin-right: 5px;
	}

	.hours_container {
		display: flex;
		flex-direction: column;
	}

	.hours_container p {
		margin: 0 !important;
	}

	.hour_start {
		font-weight: bold
	}

	.calendar_weekday_title, .calendar_weekend_title {
		font-weight: bold;
	}

	.calendar_weekend_title {
		color: red;
	}

	@keyframes translate_down {
		0% {
			transform: scale(0);
		},
		100% {
			transform: scale(1);
		}
	}
</style>
