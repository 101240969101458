<script setup>

	import { usePage, router } from '@inertiajs/vue3';
	import { onMounted, inject, ref } from "vue";
	import { useLinkedIn, LinkedInCallback } from "vue3-linkedin-login";
	import { googleSdkLoaded } from "vue3-google-login";
	import { Modal } from 'bootstrap';
	import axios from 'axios';

	const openLogin = inject('openLogin')

	const page = usePage()

	const linkedInClientId = page.props.ldn.client_id;
	const linkedInClientSecret = page.props.ldn.secret_key;

	const googleCliendId = page.props.google.client_id
	const googleSecretKey = page.props.google.secret_key
	const linkedinRoute = route('linkedin-login')
	const logoutRoute = route('logout')
    console.log(route('domain'))

	let loginModal = null

	const googleLogin = () => {
	googleSdkLoaded(google => {
	  google.accounts.oauth2.initCodeClient({
	      client_id: googleCliendId,
	      scope: "email profile openid",
	      redirect_uri: route('domain'),
	      callback: response => {
		if (response.code) {
		  sendCodeToBackend(response.code);
		}
	      }
	    })
	    .requestCode();
	})
	}

	const sendCodeToBackend = function(code) {
	      try {
		axios.post(route('google-login'), {}, {params: {
			Authorization: code
		}})
		.then(response => {
			router.reload({only: ['auth']})
			loginModal.hide()
			page.props.message_bag.push({type: 'success', text: `O login foi completo com exito! Bem vindo`})
		});

	      } catch (error) {
		console.error("Failed to send authorization code:", error);
	      }
	    }

	  const { linkedInLogin, exchangeCodeForToken, getAccount, getMail } =
	    useLinkedIn({
	      clientId: linkedInClientId,
	      clientSecret: linkedInClientSecret,
	      redirectUri: linkedinRoute,
	      onSuccess: function() {
            router.reload({only: ['auth']})
            loginModal.hide()
            page.props.message_bag.push({type: 'success', text: `O login foi completo com exito! Bem vindo`})
	      },
	      scope: "email,profile,openid",
	      onError: (error) => {
                if (error.error == "user_closed_popup") {
                    router.reload({only: ['auth']})
                    loginModal.hide()
                    page.props.message_bag.push({type: 'success', text: `O login foi completo com exito! Bem vindo`})
                }
	      },
	    });

	  onMounted(() => {
	    LinkedInCallback();
	  });

	onMounted(() => {
		loginModal = new Modal('#loginModal', {})
		document.querySelector('#loginModal').addEventListener('hidden.bs.modal', function() {
			this.querySelector('.dropdown-header').innerText = "Para acessar a plataforma escolha a sua melhor conta"
		})

		loginModal.openCustom = function(customTitle) {
			let loginModalEl = document.querySelector('#loginModal')

			if (customTitle != '') {
				loginModalEl.querySelector('.dropdown-header').innerText = customTitle

			}
			this.show()
		}

		setInterval(() => {
			if (openLogin.value.state) {
				loginModal.openCustom(openLogin.value.customTitle)
				openLogin.value.state = false
				openLogin.value.customTitle = ''
			}
		}, 100)

	})


</script>

<template>

<div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
	<strong>
		<span class="dropdown-header">Para acessar a plataforma escolha a sua melhor conta</span>
	</strong>
      </div>
	<div class="modal-body container-btns-login">
		<button title="login como o linkedin" type="button" id="ldn" @click="linkedInLogin" class="btn-login-linkedin">Linkedin</button>
		<button title="login como o google" type="button" id="gmail" @click="googleLogin" class="btn-login-google">Google</button>
	</div>
    </div>
  </div>
</div>

</template>

<style scoped>
	.modal-content {
	  border-radius: 13px !important;
	}

	.modal-header {
	  flex-direction: column;
	}

	.dropdown-header {
	  word-wrap: break-word;
	  white-space: normal;
	  font-size: 15px;
	  color: rgb(194, 117, 117);
	  font-family: sans-serif !important;
	  font-weight: bold;
	  text-align: center;
	}

	.container-btns-login {
	  display: flex;
	  justify-content: center;
	  gap: 15px;
	}

	.modal-body {
	  text-align: center;
	  position: relative;
	  flex: 1 1 auto;
	  padding: 1rem;
	}

	.btn-login-google {
	  background-color: #9C7FEF;
	  padding: 0.5rem 1.2rem;
	  border: none;
	  color: #ffff;
	  border-radius: 15px;
	}

	.btn-login-linkedin {
	  background-color: #439BF0;
	  padding: 0.5rem 1.2rem;
	  border: none;
	  color: #ffff;
	  border-radius: 15px;
	}


</style>
