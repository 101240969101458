import { createApp, onMounted, h } from 'vue'
import { useLinkedIn, LinkedInCallback } from "vue3-linkedin-login";
import { createInertiaApp } from '@inertiajs/vue3'
import { googleSdkLoaded } from "vue3-google-login";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import Paginate from "vuejs-paginate-next";
import VueSocialSharing from 'vue-social-sharing';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'swiper/css';
import '@vimeo/player';

createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob('../vue/Pages/**/**/*.vue', { eager: true })
    return pages[`../vue/Pages/${name}.vue`]
  },
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(VueSocialSharing)
      .use(Paginate)
      .mount(el)
  },
})
