<script setup>
	import { onMounted } from 'vue'
	import { usePage, router } from '@inertiajs/vue3'
	import { Alert } from 'bootstrap'

	const page = usePage()
	
	onMounted(() => {
		let messageEl = document.querySelector('#messageList')
		let messageList = [].slice.call(document.querySelectorAll('.message'))
		messageList.forEach(message => {
			let messageAlert = new Alert(message)

			message.addEventListener('click', () => {
				message.classList.add('removed_message')
			})	

			setTimeout(() => {
				if (Alert.getInstance(message) != null) {
					message.classList.add('removed_message')
				}
			}, 5000)

			message.addEventListener("transitionend", () => { 
        			messageAlert.close(); 
    			}) 
		})

		const childListObserver = new MutationObserver((mutationList, observer) => {
			mutationList.forEach(mutation => {
				if (mutation.type == "childList") {
					mutation.addedNodes.forEach(message => {
						let messageAlert = new Alert(message)

						message.addEventListener('click', () => {
							message.classList.add('removed_message')
						})	

						setTimeout(() => {
							if (Alert.getInstance(message) != null) {
								message.classList.add('removed_message')
							}
						}, 5000)

						message.addEventListener("transitionend", () => { 
							messageAlert.close(); 
						}) 
					})

					mutation.removedNodes.forEach(viewedMessage => {
						page.props.message_bag[viewedMessage.id].viewed = true
					})
				}
			})
		})

		childListObserver.observe(document.querySelector('#messageList'), {childList: true})
	})
</script>

<template>
	<div id="messageList">
		<div v-for="(message, msgIndex) in page.props.message_bag" class="message" :viewed="message.viewed" :id="msgIndex">
				<div v-if="message.type == 'error'" class="error">
					<i class="bi bi-exclamation-triangle-fill"></i> <p>{{message.text}}</p>
				</div>
				<div v-else-if="message.type == 'info'" class="info">
					<i class="bi bi-info-circle-fill"></i> <p>{{message.text}}</p>
				</div>
				<div v-else-if="message.type == 'success'" class="success">
					<i class="bi bi-check-circle-fill"></i> <p>{{message.text}}</p>
				</div>
		</div>
	</div>
</template>

<style scoped>
	#messageList {
		position: absolute;
		width: fit-content;
		height: fit-content;
		top: 1%;
		left: 1%;
		z-index: 1100;
	}

	.message {
		width: fit-content;
		min-width: 150px;
		height: 40px;
		margin: 5px;
		display: flex;
		justify-content: start;
		align-items: center;
		animation-name: popup_message;
		animation-duration: 1s;
		animation-iteration-count: 1;
		transition: 0.5s;
	}

	.removed_message {
		transform: scale(0);
		opacity: 0;
	}

	.message[viewed="true"] {
		display: none;
	}

	.message > * {
		pointer-events: none;
	}

	.message i {
		margin-right: 5px;
	}

	.error, .info, .success {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 0px 10px 0px 10px;
		box-shadow: 2px 2px 5px gray;
		border-radius: 10px;
	}

	.error p, .info p, .success p {
		margin: 0;
		padding: 0;
	}

	.error {
		color: #842029;
		background-color: #f8d7da;
		border-color: #f5c2c7;
	}

	.info {
		color: #084298;
		background-color: #cfe2ff;
		border-color: #b6d4fe;
	}

	.success {
		color: #0f5132;
		background-color: #d1e7dd;
		border-color: #badbcc;
	}

	@keyframes popup_message {
		0% {
			transform: rotate(-5deg) scale(0.5);
		}

		40% {
			transform: rotate(5deg) scale(1);
		}

		60% {
			transform: rotate(-5deg);
		}

		80% {
			transform: rotate(5deg);
		}
	}


</style>
