<script setup>

	import { onMounted, onBeforeMount, provide, ref } from "vue";
	import { useLinkedIn, LinkedInCallback } from "vue3-linkedin-login";
	import { googleSdkLoaded } from "vue3-google-login";
	import { usePage, router } from '@inertiajs/vue3'
	import axios from 'axios';
	import { Link } from '@inertiajs/vue3'
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import { Popover, Modal } from 'bootstrap';

	import Navbar from '../../components/Navbar.vue';
	import ErrorBar from '../../components/ErrorBar.vue';
	import EventCard from '../../components/EventCard.vue';
	import DropdownLogin from '../../components/DropdownLogin.vue';
	import modalSubscribe from '../../components/modalSubscribe.vue';

	const page = usePage()

	const openLogin = ref({state: false, customTitle: ''})
	const handleOpenLogin = function(customTitle = '') {
		openLogin.value.state = true
		if (customTitle != '') {
			openLogin.value.customTitle = customTitle
		}
	}

	const openSubscribe = ref({state: false, id_event: null})
	const handleOpenSubscribe = function(id_event) {
		openSubscribe.value.state = true
		openSubscribe.value.id_event = id_event
	}

	provide('openLogin', openLogin)
	provide('openSubscribe', openSubscribe)

	onMounted(() => {
		window.addEventListener("pageshow", function(event) {
		var historyTraversal = event.persisted ||
				 (typeof window.performance != "undefined" &&
				  window.performance.navigation.type === 2);
		  if (historyTraversal) {
			router.reload({only: ['auth']})
		  }
		});

		if (page.props.events.length > 0) {
			let currentEvent = document.querySelector(`#event-${page.props.events[0].id_event}`)

			let eventPopup = null;
			let saibaMaisPopup = null;
			let nextEventPopup = null;

			let eventEl = currentEvent.querySelector('.card-information-div-wrapper')
			let saibaMaisEl = currentEvent.querySelector('.saibamais-link')

			eventPopup = new Popover(eventEl, {title: 'Acessar evento', trigger: 'hover', content: 'Clique aqui para acessar o evento', placement: 'left'});

			saibaMaisPopup = new Popover(saibaMaisEl, {title: 'Saiba mais', content: 'Este é o botão de saiba mais, ao clicar, você podera acessar informações adicionais sobre o evento', trigger: 'hover', placement: 'right'})


			eventEl.addEventListener('mouseover', () => {
				eventPopup.disable()
				eventPopup.hide()
			})

			saibaMaisEl.addEventListener('mouseover', () => {
				saibaMaisPopup.disable()
				saibaMaisPopup.hide()
			})

			setInterval(() => {
				eventPopup.update()
				saibaMaisPopup.update()
			}, 50)

			setTimeout(() => {
				eventPopup.hide()
				saibaMaisPopup.hide()
			}, 5000)

			eventPopup.show()
			saibaMaisPopup.show()

			window.addEventListener('resize', () => {
				eventPopup.hide()
				saibaMaisPopup.hide()
			})
		}

	})
</script>

<template>
    <Navbar @openLogin="(customTitle) => {handleOpenLogin(customTitle)}" />
    <ErrorBar />

    <div id="content">
	    <swiper
	    :slidesPerView="2"
	    :centeredSlides="true"
	    class="mySwiper"
	  >
			<swiper-slide v-for="event in page.props.events">
				<EventCard @openLogin="(customTitle) => {handleOpenLogin(customTitle)}" @openSubscribe="(id_event) => {handleOpenSubscribe(id_event)}" :event="event" :array_btns="page.props.array_btns"/>
			</swiper-slide>
	  </swiper>

	  <DropdownLogin />
	  <modalSubscribe />

    </div>
</template>

<script>
</script>

<style scoped>
	@media (orientation: portrait) or (width < 600px) {
		.swiper-slide {
			width: 60% !important;
		}
	}

	.swiper {
		position:relative;
		width: 100%;
		height: 70%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper > .swiper-slide__content {
		position:absolute;
		top:0;
	}

	.swiper-wrapper {
		width: 100% !important;
		height: 100% !important;
		justify-content: center !important;
		align-items: center !important;
	}

	.swiper-slide {
		height: 100% !important;
		width: 50%;
		display:flex;
		align-items: center;
		justify-content:center;
		transition:all 200ms linear;
		transform: scale(0.8);
		animation-name: slide_cards;
		animation-duration: 1s;
		animation-iteration-count: 1;
		transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
	}

	#content {
		height: inherit;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #f4f6f9;
	}

	@keyframes move_arrow {
		50% {
			right: 4%;
		}

		100% {
			right: 3%;
		}
	}

	@keyframes slide_cards {
		0% {
			margin-left: 100%;
		}

		80% {
			margin-left: -3%;
		}

		100% {
			margin-left: 0%;
		}
	}
</style>
