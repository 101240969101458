<script setup>
	import { onMounted, ref, defineEmits, computed } from 'vue';
	import { Dropdown } from 'bootstrap';
	import { usePage } from '@inertiajs/vue3'

	const page = usePage()

	const emit = defineEmits([
	'openLogin'
	])

    let homeRoute = route('ev.events.index')
	let myeventsRoute = route('ev.events.myevents')
	let favoritesRoute = route('ev.events.favorites')
	let myactivitiesRoute = route('ev.events.myactivities')
	let accRoute = route('ev.user.profile')
	let logoutRoute = route('logout')

	const mobileMenu = ref(false)

	onMounted(() => {
		if (window.innerWidth <= 600) {
			mobileMenu.value = true
		} else {
			mobileMenu.value = false
		}


		window.addEventListener('resize', () => {
			if (window.innerWidth <= 600) {
				mobileMenu.value = true
			} else {
				mobileMenu.value = false
			}

		})
	})

</script>

<template>
    <div class="navbar-container">
	    <div v-if="!page.props.auth.isLogged" class="main-header navbar navbar-white navbar-light" id="navBar" logged="false">
		    <a class="navbar-brand" href="#">
			<img src="https://app.geolivre.com.br/img/logo_plataforma.webp">
		    </a>

		<p v-if="page.props.auth.isLogged" class="login-text">Olá, {{page.props.auth.user.nickname != null ? page.props.auth.user.nickname : page.props.auth.user.username}}!</p>
		<p v-else @click="emit('openLogin')" class="login-text">Login</p>

	    </div>
	    <div v-else class="main-header navbar navbar-white navbar-light" id="navBar" logged="true">
		<div class="d-flex">
		    <a class="navbar-brand" href="#">
			<img src="https://app.geolivre.com.br/img/logo_plataforma.webp">
		    </a>
		</div>

		<div class="navbar-menus" v-if="!mobileMenu">
			<a class="navbar-dropdown-link" :href="myactivitiesRoute">Minhas Atividades</a>
			<div class="btn-group">
			  <button type="button" class="navbar-dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
				Eventos
			  </button>
			  <ul class="dropdown-menu dropdown-menu-end">
			    <li><a class="dropdown-item" type="button" :href="homeRoute">Home</a></li>
			    <li><a class="dropdown-item" type="button" :href="myeventsRoute">Meus Eventos</a></li>
			    <li><a class="dropdown-item" type="button" :href="favoritesRoute">Favoritos</a></li>
			  </ul>
			</div>
			<div class="btn-group">
			  <button type="button" class="navbar-dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
				{{page.props.auth.user.nickname != null ? page.props.auth.user.nickname : page.props.auth.user.username}}
				<i class="bi bi-person-fill"></i>
			  </button>
			  <ul class="dropdown-menu dropdown-menu-end">
			    <li><a class="dropdown-item" type="button" :href="accRoute">Conta</a></li>
			    <li><a class="dropdown-item" type="button" :href="logoutRoute">Sair</a></li>
			  </ul>
			</div>
		</div>

		<div class="navbar-menus" v-else>
			<div class="btn-group">
			  <button type="button" class="navbar-dropdown-toggle mobile-menu" data-bs-toggle="dropdown" aria-expanded="false">
				<i class="bi bi-list"></i>
			  </button>
			  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-mobile">
			    <div class="mobile-menu-container">
				    <div class="mobile-menu-section">
					    <li><h6 class="dropdown-header">Atividades</h6></li>
					    <li><a class="dropdown-item" :href="myactivitiesRoute">Minhas Atividades</a></li>
				    </div>
				    <div class="mobile-menu-section">
					    <li><h6 class="dropdown-header">Eventos</h6></li>
					    <li><a class="dropdown-item" type="button" :href="homeRoute">Home</a></li>
					    <li><a class="dropdown-item" type="button" :href="myeventsRoute">Meus Eventos</a></li>
					    <li><a class="dropdown-item" type="button" :href="favoritesRoute">Favoritos</a></li>
				    </div>
				    <div class="mobile-menu-section">
					    <li>
						<h6 class="dropdown-header">
							{{page.props.auth.user.nickname != null ? page.props.auth.user.nickname : page.props.auth.user.username}}
							<i class="bi bi-person-fill"></i>
						</h6>
					    </li>
					    <li><a class="dropdown-item" type="button" :href="accRoute">Conta</a></li>
					    <li><a class="dropdown-item" type="button" :href="logoutRoute">Sair</a></li>
				    </div>
			    </div>
			  </ul>
			</div>
		</div>

	    </div>
    </div>
</template>

<style scoped>
	#navBar {
		border-bottom: 0.2px solid;
	}


	.navbar-container {
		margin: 0;
		padding: 0;
	}

	.navbar-menus {
		height: 100%;
		display: flex;
		align-items: center;
	}

	.login-text {
		font-size: 0.9em;
		color: grey;
		margin-bottom: 0;
	}

	.navbar-dropdown-toggle, .navbar-dropdown-link {
		margin-right: 20px;
		background-color: transparent;
		border: none;
		color: #7f7f7f !important;
		font-size: 15px !important;
        display: flex;
        align-items: center;
        justify-content: center;
	}

    .navbar-dropdown-toggle i {
        margin-left: 5px;
    }

    .mobile-menu i {
        margin: 0 !important;
    }

	.navbar-dropdown-toggle::after {
		display: inline-block;
		margin-left: .255em;
		vertical-align: .255em;
		content: "";
		border-top: .3em solid;
		border-right: .3em solid transparent;
		border-bottom: 0;
		border-left: .3em solid transparent;
	}

	.dropdown-menu-mobile {
		width: 90vw;
	}

	.mobile-menu {
		margin-right: 0px !important;
		height: 30px;
		width: 30px;
		border: 1px solid gray;
		border-radius: 5px;
		transition: 0.1s ease-in-out;
	}

	.mobile-menu:hover {
		border: 3px solid gray !important;
	}

	.mobile-menu::after {
		display: none !important;
	}

	.mobile-menu-container {
		display: flex;
		width: 100%;
		align-items: baseline;
		flex-wrap: wrap;
		max-width: fit-content;
	}

    .mobile-menu-section h6 {
        display: flex;
        align-items: center;
    }

    .mobile-menu-section h6 i {
        margin-left: 5px;
    }

</style>
