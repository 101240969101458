<script setup>
	import { usePage, router } from '@inertiajs/vue3';
	import { onMounted } from 'vue';
	import { Modal } from 'bootstrap';
	import axios from 'axios';
	import Navbar from '../../components/Navbar.vue';
	import ErrorBar from '../../components/ErrorBar.vue';
	import ConfirmFormModal from '../../components/modals/ConfirmFormModal.vue';

	const page = usePage()
	const eventsRoute = route('ev.events.index')

	let confirmFormModal = null

	let dateBRFull = new Intl.DateTimeFormat('pt-BR', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		fractionalSecondDigits: 3,
	})

	const handleFormSubmit = function(event) {
		event.preventDefault()

		page.props.message_bag.push({'type': 'info', 'text': 'Salvando alterações...'})
		setTimeout(() => {
			page.props.message_bag.push({'type': 'success', 'text': 'Alterações salvas com sucesso!'})

			let elementList = event.target.elements
			axios.post(route('ev.user.edit-profile'), {}, {params: {
					nickname: elementList.nickname.value,
					first_name: elementList.first_name.value,
					last_name: elementList.last_name.value,
					cellphone: elementList.cellphone.value,
					role: elementList.role.value,
					company: elementList.company.value,
					gender: elementList.gender.options[elementList.gender.selectedIndex].value,
					linkedin: elementList.linkedin.value,
				}
			}).then((response) => {
				router.reload({only: ['auth', 'user_details']})
			})

		}, 750)

	}

	const checkForChanges = () => {
		let infoFormElements = document.querySelector('#info-form').elements
		let changes = false

		for (let [key, value] of Object.entries(page.props.auth.user)) {
			try {
				if (value == null) {
					value = ''
				}


				if (key == 'gender') {
					if (value != infoFormElements[key].options[infoFormElements[key].selectedIndex].value) {
                    console.log('normal input: ', key, value, infoFormElements[key].options[infoFormElements[key].selectedIndex].value)
						confirmFormModal.show()
						changes = true
						break
					}

				}

				if (value != infoFormElements[key].value) {
                    console.log('normal input: ', key, value)
					confirmFormModal.show()
					changes = true
					break
				}

			} catch {
				continue
			}
		}


		for (let [key, value] of Object.entries(page.props.user_details)) {
			try {
				if (value == null) {
					value = ''
				}

				if (value != infoFormElements[key].value) {
                    console.log('user_detials: ', key, value)
					confirmFormModal.show()
					changes = true
					break
				}
			} catch {
				continue
			}
		}

		if (!changes) {
			window.history.back()
		}
	}

	onMounted(() => {
		confirmFormModal = new Modal(document.querySelector('#confirm-form-modal'))
		let inputList = [].slice.call(document.querySelectorAll('.form-control'))
		inputList.forEach(input => {
			input.onkeypress = function(e) {
				if (e.keycode == 13 || e.charCode == 13) {
					e.preventDefault()
					document.querySelector('#info-form').requestSubmit()
					return false
				}
			}
		})

		window.addEventListener("pageshow", function(event) {
		  var historyTraversal = event.persisted ||
					 (typeof window.performance != "undefined" &&
					  window.performance.navigation.type === 2);
		  if (historyTraversal) {
			router.reload({only: ['auth', 'user_details']})
		  }
		});
	})

</script>


<template>
	<Navbar />
	<ErrorBar />

	<div id="content">
		<ConfirmFormModal />

		<div class="created-date">
			<p class="return-link" @click="checkForChanges"><span><i class="bi bi-arrow-left"></i> Retornar</span></p> <p class="accDate">Conta criada em {{dateBRFull.format(new Date(page.props.auth.user.created.replace('T', ' ').replace('Z', ' ')))}}</p>
		</div>

		<form id="info-form" @submit="handleFormSubmit" >
			<div class="basic-info">
				<label>
					Email
					<input :value="page.props.auth.user.email" class="form-control" id="email" name="email" readonly="readonly" />
				</label>
				<label>
					Apelido
					<input id="nickname" name="nickname" :value="page.props.auth.user.nickname" class="form-control" />
				</label>
				<label>
					Nome
					<input :value="page.props.auth.user.first_name" class="form-control" id="first_name" name="first_name" required />
				</label>
				<label>
					Sobrenome
					<input :value="page.props.auth.user.last_name" class="form-control" id="last_name" name="last_name" required />
				</label>
			</div>

			<div class="extra-info">
				<div class="extra-info-title">
					<img class="meteor-icon" src="../../assets/icon_fireball.jpeg">

					<span class="title-span">
						<b>Participe das nossas premiações.</b> <br>
						Complete seu cadastro:
					</span>
				</div>

				<div class="extra-info-inputs">
					<label>
						Telefone
						<input :value="page.props.user_details.cellphone" class="form-control" id="cellphone" name="cellphone" />
					</label>
					<label>
						Cargo/Função
						<input :value="page.props.auth.user.role" class="form-control" id="role" name="role" />
					</label>
					<label>
						Empresa
						<input :value="page.props.auth.user.company" class="form-control" id="company" name="company" />
					</label>
				</div>
			</div>
			<div class="extra-info networking-info">
				<div class="extra-info-title">
					<i class="bi bi-diagram-3-fill diagram-i"></i>
					<span class="title-span">
						<b>Participe do nosso networking.</b> <br>
						Complete seu cadastro:
					</span>
				</div>

				<div class="extra-info-inputs">
					<label>
						Gênero
						<select id="gender" name="gender" class="form-control">
							<option v-if="page.props.auth.user.gender != null" :value="page.props.auth.user.gender" selected>{{page.props.auth.user.gender}}</option>
							<option value="" v-else selected>Selecione o gênero</option>
							<option v-if="page.props.auth.user.gender == null || page.props.auth.user.gender != 'Male'" value="Male">Male</option>
							<option v-if="page.props.auth.user.gender == null || page.props.auth.user.gender != 'Female'" value="Female">Female</option>
							<option v-if="page.props.auth.user.gender == null || page.props.auth.user.gender != 'Prefiro nao informar'" value="Prefiro nao informar">Prefiro não informar</option>
						</select>
					</label>
					<label>
						Linkedin
						<input :value="page.props.auth.user.linkedin" class="form-control" id="linkedin" name="linkedin" placeholder="https://linkedin.com.br"/>
					</label>
				</div>
			</div>
		</form>
	</div>
</template>
<style>
	@media (width < 700px) {
		.created-date {
			flex-direction: column;
		}

		.accDate {
			width: 100% !important;
		}

		.basic-info {
			flex-direction: column;
		}

		.extra-info {
			flex-direction: column;
		}

		.extra-info-inputs {
			width: -moz-available !important
		}
	}

	.created-date {
		display: flex;
  		align-items: center;
		width: fit-content;
  		justify-content: space-between;
  		margin: 20px auto 0px auto;
		border-bottom: 1px solid #999999;
	}

	.return-link {
		width: 50%;
		margin: 0;
		padding: 0;
	}

	.return-link span {
		font-size: 35px;
		padding-top: 0rem;
		color: #414BB2 !important;
		display: flex;
		align-items: center;
	}

	.return-link i {
		margin-right: 10px;
	}

	.return-link i::before {
		transform: scale(1.5);
	}

	.accDate {
		width: 50%;
		padding: 0;
		margin: 0;
		color: #808079;
		font-weight: bold;
	}

	.basic-info {
		display: flex;
  		justify-content: space-around;
	}

	label {
  		display: flex;
 	 	flex-direction: column;
		width: -moz-available;
		margin: 15px;
		font-weight: 700;
	}

	label input {
		margin-top: 10px;
	}

	.form-control {
		display: block;
		width: 100%;
		height: calc(2.25rem + 2px);
		padding: .375rem .75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: .25rem;
		box-shadow: inset 0 0 0 transparent;
		transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	}

	.form-control[readonly] {
  		background-color: #e9ecef;
  		opacity: 1;
	}

	.extra-info {
		background-color: #B3B9F1;
		display: flex;
		align-items: center;
		padding: 1rem;
		margin: 10px;
		border-radius: 13px;
		height: fit-content;
		justify-content: space-around;
	}

	.extra-info-title {
		display: flex;
		flex-direction: column;
		height: fit-content;
		justify-content: center;
		align-items: center;
		width: 40%;
	}

	.meteor-icon {
		height: 100px;
		width: 100px;
		margin-bottom: 15px;
	}

	.extra-info-inputs {
		height: fit-content;
		width: 60%;
	}

	.title-span {
		font-size: 2.5vh;
		text-align: center;
	}

	.title-span b {
		font-size: 25px;
	}

	.diagram-i {
		height: 80px;
		width: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;
	}

	.diagram-i::before {
		transform: scale(5.5);
		color: #652bb3;
	}

	.networking-info {
		height: 20% !important;
	}

	.submit-options {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		color: #808080;
	}

	.yes-submit-opt {
		padding: 0.5rem 2rem;
		border-radius: 360px;
		background-color: #652CB3;
		transition: all ease 0.3s;
		margin-left: 10px;
		color: white;
		border: none;
	}

	.no-submit-opt {
		padding: 0.5rem 2rem;
		border-radius: 360px;
		background-color: gray;
		transition: all ease 0.3s;
		margin-left: 10px;
		color: white;
		border: none;
	}
</style>
