<script setup>

  import { onMounted, ref, inject } from 'vue';
  import { Modal } from 'bootstrap';

  const openSubscribe = inject('openSubscribe');
	function openSubscribeModal(id_event) {
		let subscribeModalLink = document.querySelector('#modalSubscribeLink')
		subscribeModalLink.href = route('ev.events.inscrever', {eventID: id_event})

        const subscribeModalElement = document.getElementById('modalSubscribe');
        const modalSubscribe = new Modal(subscribeModalElement, {});
        modalSubscribe.show();
  }

  onMounted(() => {
    const interval = setInterval(() => {
      if (openSubscribe.value.state) {
        openSubscribeModal(openSubscribe.value.id_event);
        openSubscribe.value.state = false;
        openSubscribe.value.id_event = null;
      }
    }, 100);

    // Clean up the interval on component unmount
    // return () => clearInterval(interval);
  });

</script>

<template>

  <div class="modal fade" id="modalSubscribe" aria-hidden="true" aria-labelledby="exampleModalToggleAceiteLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="rowIconePremios">
                        <span>
                            <b>Termo de Aceite</b>
                        </span>
                        <br>
                        <span class="mt-2">
                            <a href="https://docs.google.com/document/d/1NPSKdoRSqy0Zvzt4eZYrcpH7rAiDRV1a9d1PdzdbGZs/edit?usp=sharing" target="__blank" style="color: #652CB3">Clique aqui</a> para visualizar os termos para participação do evento
                        </span>
                    </div>
                </div>
            </div>

            <div class="row text-center mt-3">
                <div class="col-md-1"></div>
                <div class="col-md-10">
		    <a id="modalSubscribeLink" class="btn btn-completar-cad" href="#">Aceitar e Inscrever</a>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
	#exampleModalToggleAceite .modal-dialog .modal-content {
		border-radius: 10px;
		background-color: #B3B9F1;
	}

	.modal-content {
		border-radius: 13px !important;
	}

	.modal-body {
		text-align: center;
	}

	.btn-completar-cad {
		background-color: #652CB3;
		color: white;
		font-weight: bold;
	}

	.rowIconePremios span {
		font-size: 2.5vh;
	}

	b {
		font-weight: bolder;
	}
</style>
