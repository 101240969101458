<script setup>
    import { onMounted, ref, inject } from 'vue'
    import axios from 'axios'

    import Player from '@vimeo/player'
    let vimeoUrl = "https://player.vimeo.com/video"


    const props = defineProps([
        'videoInfo'
    ])

    let pauseVimeoVideo = inject('pauseVimeoVideo')


    const updateView = (player) => {
            if (props.videoInfo.id_userlog == null && props.videoInfo.videospaceverse_id != null) {
                axios.post(
                    route('ev.v-spaceverses.save-view',
                        {
                            _query: {
                                videospaceverse_id: props.videoInfo.videospaceverse_id
                            }
                        }
                    )
                ).then(response => {
                    props.videoInfo.id_userlog = response.data.id_userlog
                })
            } else if (props.videoInfo.id_userlog != null && props.videoInfo.videospaceverse_id != null) {
                player.getCurrentTime().then(time_watched => {
                    axios.post(
                        route('ev.v-spaceverses.save-view',
                            {
                                _query: {
                                    id_userlog: props.videoInfo.id_userlog,
                                    videospaceverse_id: props.videoInfo.videospaceverse_id,
                                    time_watched: time_watched
                                }
                            }
                        )
                    ).then(response => {
                        if (response.data.success) {
                            props.videoInfo.viewed = true
                        }
                    })

                })
            }
    }

    onMounted(() => {
        if (props.videoInfo.video_id != null) {

            let min_time_watched = 0

            let vimeoPlayerEL = document.querySelector('#vimeo_player')
            let vimeoPlayer = new Player(vimeoPlayerEL)
            let updateViewInterval;

            setInterval(() => {
                if (pauseVimeoVideo.value == true) {
                    if (min_time_watched >= 5) {
                        updateView(vimeoPlayer)
                    }
                    vimeoPlayer.pause()
                    pauseVimeoVideo.value = false
                }
            }, 100)

            vimeoPlayer.on('play', () => {
                if (updateViewInterval == null) {
                    updateViewInterval = setInterval(() => {
                        min_time_watched = min_time_watched + 0.1

                        if (min_time_watched >= 5) {
                            updateView(vimeoPlayer)
                            clearInterval(updateViewInterval)
                        }
                    }, 100)
                }
            })

            vimeoPlayer.on('pause', () => {
                if (updateViewInterval != null) {
                    clearInterval(updateViewInterval)
                }
            })


            vimeoPlayer.on('ended', () => {
                updateView(vimeoPlayer)
            })
        }
    })
</script>

<template>
    <iframe v-if="props.videoInfo.video_id != null" :src="`${vimeoUrl}/${props.videoInfo.video_id}`" id="vimeo_player" class="vimeo_player"></iframe>
</template>

<style scoped>
    .vimeo_player {
        height: 250px;
        width: 100%;
    }
</style>
