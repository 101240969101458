<script setup>
    import { onMounted, ref, provide, inject } from 'vue'
	import { usePage } from '@inertiajs/vue3'

    import Player from '@vimeo/player'
    import axios from 'axios'

    import { Modal } from 'bootstrap'

    import VimeoPlayer from '../VimeoPlayer.vue'

    const page = usePage();

    const openStandModal = inject('openStandModal')

    const standModal = () => {
        let standModal = Modal.getOrCreateInstance(document.querySelector('#standModal'))
        return standModal
    }

    const networkingModal = () => {
        return Modal.getOrCreateInstance(document.querySelector("#networking_modal"))
    }

    let standActive = ref(false)
    let vimeoVideoInfo = ref(null)
    let pauseVimeoVideo = ref(false)

    provide('pauseVimeoVideo', pauseVimeoVideo)

    const downloadFile = (id) => {
        window.location.href = page.props.URLs.downloadsDomain + "/downloads/downloadFile/" + id;
    }

    const prepareStand = () => {
        standActive.value = false
        vimeoVideoInfo.value = null

        vimeoVideoInfo.value = {
            viewed: false,
            id_userlog: null,
            video_id: openStandModal.value.stand_data.sv_video[0],
            videospaceverse_id: openStandModal.value.stand_data.sv_video[1]
        }

        standActive.value = true

        standModal().show()
    }

    const toggleToNetworkingStand = () => {
        standModal().toggle()
        networkingModal().toggle()
    }

    const closeStand = () => {
        standModal().hide()

        pauseVimeoVideo.value = true
    }

    onMounted(() => {

        setInterval(() => {
            if (openStandModal.value.state) {

                prepareStand()

                openStandModal.value.state = false
            }
        }, 500)
    })
</script>

<template>
    <div class="modal" tabindex="-1" role="dialog" id="standModal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div v-if="standActive" class="modal-header">
              <h5 class="modal-title" >{{openStandModal.stand_data.sv_data.title}}</h5>
              <button type="button" class="close_modal" @click="closeStand()">
                    <span aria-hidden="true">×</span>
            </button>
          </div>
          <div v-if="standActive" class="modal-body">
              <VimeoPlayer :videoInfo="vimeoVideoInfo" />
              <div class="action_buttons">
                <a :href="openStandModal.stand_data.sv_data.link_room">
                  <div class='action_button'>
                      <i class="bi bi-camera-video-fill"></i>
                      <p>Entrar</p>
                  </div>
                </a>
                <a href="https://forms.gle/bhXmXcQaymyytrPE6">
                  <div class='action_button'>
                      <i class="bi bi-bug-fill"></i>
                      <p>Reportar bug</p>
                  </div>
                </a>

                <a href="#" @click="toggleToNetworkingStand">
                    <div class="action_button">
                        <i class="bi bi-people-fill"></i>
                        <p>Networking</p>
                    </div>
                </a>

              </div>
            <img class="banner_img" src="../../assets/banner_estande_default.png"></img>

            <div class="descriptions_container">
                <p class="short_desc">{{openStandModal.stand_data.sv_data.short_description}}</p>
                <p class="long_desc">{{openStandModal.stand_data.sv_data.description}}</p>
            </div>


            <div class="docs_table_container">
                <table class="docs_table">
                    <tr class="table_header_row">
                        <th>Anexos</th>
                    </tr>
                    <tr class="table_body_row" v-for="doc in openStandModal.stand_data.sv_table">
                        <td>
                            <p>{{doc.text}}</p>
                            <button v-if="doc.is_link"class="link_button"><a :href="doc.value">Ir para o link</a></button>
                            <button v-else class="download_button"><a @click="downloadFile(doc.value)">Baixar</a></button>
                        </td>
                    </tr>
                </table>
            </div>
          </div>

          <div class="modal-footer">
              <img src="../../assets/logo_plataforma.webp"></img>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
    #standModal {
        animation: fade_up ease-in-out 0.5s;
    }

    .modal-header {
        border-bottom: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-title {
        color: #414bb2;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
    }

    .close_modal {
        background-color: transparent;
        border: none;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .close_modal span {
        font-size: 20px;
        font-weight: bold;
        color: #7e7e7e;
    }

    .action_buttons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
    }

    .action_button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .action_button i {
        height: 40px !important;
        width: 40px !important;
        border: 1px solid #7e7e7e !important;
        border-radius: 50% !important;
    }

    .action_button i::before {
        transform: scale(1.3) !important;
        color: #414bb2;
    }

    .banner_img {
        width: 100%;
    }

    .descriptions_container {
        margin-top: 20px;
    }

    .short_desc {
        word-break: break-all;
        margin: 0;
        font-size: 25px;
        font-weight: bold;
    }

    .long_desc {
        word-break: break-all;
        font-size: 20px;
    }

    .docs_table_container {
        padding: 0;
        margin: 0;
        height: 250px;
        overflow: scroll;
    }

    .docs_table {
        height: 100%;
        width: 100%;
    }

    .docs_table tr {
        border-bottom: 1px solid #7e7e7e;
    }

    .docs_table .table_header_row {
        height: 50px;
        background-color: #e9ecef;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .docs_table .table_header_row th {
        text-align: center;
        font-weight: bold;
    }

    .docs_table .table_body_row {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .docs_table .table_body_row td {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .docs_table .table_body_row td p {
        margin: 0;
        width: 70%;
    }

    .docs_table .table_body_row td button {
        width: 30%;
        border: none;
        background-color: #5e85c3;
        border-radius: 5px;
        color: white;
        height: 70%;
        margin-right: 10px;
    }

    .docs_table .table_body_row td button a {
        height: 100%;
        display: block;
        width: 100%;
        align-content: center;
        text-align: center;
    }

    .modal-footer {
        justify-content: center;
        align-items: center;
    }

    .modal-footer img {
        width: 150px;
        height: 20px;
    }

    @keyframes fade_up {
        0% {
            transform: translateY(100%);
            opacity: 0;
        };
        100% {
            transform: translateY(-10%);
            opacity: 1;
        }
    }

</style>
