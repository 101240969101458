export class LayerStyle {
    getColor(color: string, from: number) {
        if (from == 0) {
            return color == undefined ? 'transparent': color;
        } else {
            return color == undefined ? '#7F1DC3': color;
        }
    }

    floor(feature: any) {
        return {
            fillColor: this.getColor(feature.geometry.colorSelected, 0),
            weight: 2,
            opacity: 1,
            color: 'transparent',
            dashArray: '3',
            fillOpacity: 0.3
        }
    }

    highlight() {
        return {
            fillColor: 'transparent',
            weight: 2,
            opacity: 1,
            color: 'transparent',
            dashArray: '3',
            fillOpacity: 0
        }
    }
}