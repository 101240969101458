<script setup>
	import { onMounted, ref, provide } from "vue"
	import { usePage, router } from '@inertiajs/vue3'

	import Navbar from '../../components/Navbar.vue'
	import ErrorBar from '../../components/ErrorBar.vue'
	import SaibaMaisCard from '../../components/SaibaMaisCard.vue'
    import DropdownLogin from '../../components/DropdownLogin.vue'
    import ModalSubscribe from '../../components/modalSubscribe.vue'

	const page = usePage()

	const openLogin = ref({state: false, customTitle: ''})
	const handleOpenLogin = function(customTitle = '') {
		openLogin.value.state = true
		if (customTitle != '') {
			openLogin.value.customTitle = customTitle
		}
	}

	const openSubscribe = ref({state: false, id_event: null})
	const handleOpenSubscribe = function(id_event) {
		openSubscribe.value.state = true
		openSubscribe.value.id_event = id_event
	}

	provide('openLogin', openLogin)
	provide('openSubscribe', openSubscribe)

        let renameBanner = page.props.saibaMais.image_hash;
        if (renameBanner != null) {
		renameBanner = renameBanner
			.split('/')
			.filter((URLseg) => {
				if (URLseg != "html" && URLseg != "webroot") {
					return true
				}
			})
			.join('/')
        }
	page.props.renameBanner = renameBanner

	onMounted(() => {
		document.querySelector('.background-blur').style.backgroundImage = `url(${renameBanner})`
		window.addEventListener("pageshow", function(event) {
		  var historyTraversal = event.persisted ||
					 (typeof window.performance != "undefined" &&
					  window.performance.navigation.type === 2);
		  if (historyTraversal) {
			router.reload({only: ['auth']})
		  }
		});
	})
</script>

<template>
	<Navbar @openLogin="(customTitle) => {handleOpenLogin(customTitle)}" />
	<ErrorBar />

	<div id="content">
		<div class="background-absolute">
			<div class="background-blur">
			</div>

			<SaibaMaisCard @openLogin="(customTitle) => {handleOpenLogin(customTitle)}" @openSubscribe="(id_event) => {handleOpenSubscribe(id_event)}"/>
		</div>
	  <DropdownLogin />
	  <ModalSubscribe />
	</div>
</template>

<style scoped>

	#content {
		height: inherit;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #f4f6f9;
	}

	.background-absolute {
		height: 100%;
		width: 100%;
	}

	.background-blur {
		margin-top: 10px;
		width: 100%;
		height: 90vh;
		z-index: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		filter: blur(8px);
	}
</style>
