<script setup>
	import { onMounted, ref } from 'vue'
	import { usePage } from '@inertiajs/vue3'
	import { Popover, Modal } from 'bootstrap'
	import axios from 'axios'

    	import DropdownLogin from './DropdownLogin.vue'
    	import ModalSubscribe from './modalSubscribe.vue'

	const page = usePage()
	const data = ref(page.props.data)
	const saibaMais = ref(page.props.saibaMais)

	const emit = defineEmits([
		'openLogin',
		'openSubscribe'
	])

	const formatOptions = { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3 }
	let currencyBR = new Intl.NumberFormat('pt-BR', formatOptions);

	let dateBRFull = new Intl.DateTimeFormat('pt-BR', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: 'numeric',
		timeZone: 'America/Sao_Paulo',
		fractionalSecondDigits: 3,
	})

	let dateBR = new Intl.DateTimeFormat('pt-BR', {
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timeZone: 'America/Sao_Paulo',
	})
	let dateBRShort = new Intl.DateTimeFormat('pt-BR', {
		month: '2-digit',
		day: '2-digit',
		timeZone: 'America/Sao_Paulo',
	})
	let dateBRHours = new Intl.DateTimeFormat('pt-BR', {
		hour: '2-digit',
		minute: '2-digit',
		timeZone: 'America/Sao_Paulo',
	})

	let dateNow = dateBRFull.format(new Date())

	saibaMais.value.eventactivities.forEach(activity => {
		activity.start_date_time_full = dateBRFull.format(new Date(activity.start_date_time))
		activity.end_date_time_full = dateBRFull.format(new Date(activity.end_date_time))

		activity.start_date_time_formated = dateBRShort.format(new Date(activity.start_date_time))
		activity.end_date_time_formated = dateBRShort.format(new Date(activity.end_date_time))

		activity.start_date_time_hours = dateBRHours.format(new Date(activity.start_date_time))
		activity.end_date_time_hours = dateBRHours.format(new Date(activity.end_date_time))

	})

	let valueEventDiscount = saibaMais.value.discount_percentage;
	let valueEventFull = saibaMais.value.price;
	let valueTotal = saibaMais.value.full_value - saibaMais.value.discount_percentage * valueEventFull;

	let valueDiscountFormated = currencyBR.format(saibaMais.value.discount_percentage);
	let valueEventDiscountFormated = currencyBR.format(valueEventDiscount);
	let valueEventFullFormated = currencyBR.format(valueEventFull);
	let valueTotalFormated = currencyBR.format(valueTotal);

	let valueEvent = null

	if (valueEventDiscount == 0 && valueEventFull != 0) {
		valueEvent = "Sem Desconto";
		valueEvent = `Normal: ${valueEventFullFormated}`

	} else if (valueEventFull == 0) {
		valueEvent = "Gŕatis"
	} else {
		valueEvent = `Desconto: ${valueEventDiscountFormated}`
		valueEvent = `Normal: ${valueEventFullFormated}`
	}

	let startAct = new Date(saibaMais.value.start_date)
	let endAct = new Date(saibaMais.value.end_date)
	let startEvent = dateBR.format(startAct)

	let currentRoute = route(route().current(), {id_event: saibaMais.value.id_event});
	let subscribeRoute = route('ev.events.inscrever', {eventID: saibaMais.value.id_event});
	let calendarRoute = route('ev.events.calendar', {id: saibaMais.value.id_event});
	let multiverseRoute = route('ev.multiverse.view', {id: saibaMais.value.multiverse_id});

	let icon_favorite = ref(null)
	if (data.value.favorite_list.includes(saibaMais.value.id_event)) {
		icon_favorite.value = '<i class="bi bi-star-fill"></i>';
	} else {
		icon_favorite.value = '<i class="bi bi-star"></i>';
	}

	let title_btn = null
	if (data.value.subscribe_list.includes(saibaMais.value.id_event)) {
		title_btn = 'Participar';
	} else {
		title_btn = 'Inscrever';
	}

	const updateCardPos = () => {
		let saibaMaisContainer = document.querySelector('.saibamais-container')
		saibaMaisContainer.style.marginLeft = `-${saibaMaisContainer.offsetWidth / 2}px`
		saibaMaisContainer.style.marginTop = `-${saibaMaisContainer.offsetHeight / 2}px`
	}

	const favoriteEvent = () => {
		axios.post(route('ev.events.favoritar', {id_event: saibaMais.value.id_event}))
		.then((response) => {
			if(response.data.favorites) {
				icon_favorite.value = '<i class="bi bi-star-fill"></i>';
				page.props.message_bag.push({'type': 'success', 'text': `Evento ${saibaMais.value.event_name} favoritado com sucesso`})
			} else {
				icon_favorite.value = '<i class="bi bi-star"></i>';
				page.props.message_bag.push({'type': 'info', 'text': `Evento ${saibaMais.value.event_name} desfavoritado com sucesso`})
			}

			data.value.qntFavorites = response.data.qntFavorites
		})
	}

	let shareModal = null

	onMounted(() => {
		updateCardPos()
		window.addEventListener('resize', updateCardPos)

		let shareEl = document.querySelector('.share-friends')
		let dateTimeEl = document.querySelector('.date-n-time-title')

		let sharePopup = new Popover(shareEl, {title: 'Compartilhe com seus amigos', trigger: 'hover', content: 'Use o botão de compartilhamento para mostrar a todos este evento', placement: 'left'});
		let dateTimePopup = new Popover(dateTimeEl, {title: 'Datas e Horários', trigger: 'hover', content: 'Aqui você pode ver as várias atividades do evento, caso haja alguma registrada', placement: 'top'});

		setInterval(() => {
			sharePopup.update()
			dateTimePopup.update()
		}, 50)

		setTimeout(() => {
			sharePopup.hide()
			dateTimePopup.hide()
		}, 5000)

		sharePopup.show()
		dateTimePopup.show()

		shareModal = Modal.getOrCreateInstance('#shareFriendsModal', {})

		const btn_copyURL = document.getElementById('btn_copy_url')
		const fieldUrl = document.getElementById('field_url')

		fieldUrl.value = currentRoute

		// GET URL FROM FIELD URL
		btn_copyURL.addEventListener('click', (e) => {
			document.getElementById('field_url').select();
			document.execCommand('copy');
		})
	})

</script>

<template>
	<div class="saibamais-container">
	      <section class="saibamais-content">
		<div class="saibamais-img">
		  <img :src="page.props.renameBanner" alt="banner_evento">
		</div>

		<div class="saibamais-descritpion">
		  <h4> {{startEvent}} </h4>
		  <h1 class="title-event">{{saibaMais.event_name}}</h1>

		  <div class="icons-and-gratis">
		    <div class="content-owner-and-qntfavoritados">
		    </div>
		  </div>
			<p class='value-event'>{{valueEvent}}</p>
		</div>


	      </section>

	      <section class="container-share-and-participate">
		  <div class="icons-share" style="display: flex; justify-content: center;">
		   <button class="share-friends" @click="shareModal.show()"><i class="bi bi-share"></i></button>
		      <button v-if="page.props.auth.isLogged" id='btn-favorited-events' title='Favoritar evento' class='btn-favorites' value='$saibaMais->id_event' @click="favoriteEvent" > <span v-html="icon_favorite"></span></button>
		      <button id='btn-favorited-events' title='Favoritar evento' class='btn-favorites' @click="emit('openLogin', 'Para favoritar esse evento, escolha a sua melhor conta')"  v-else> <span v-html="icon_favorite"></span> </button>

		    <span id="qnt-favorites" class="qnt-favoritados" style="align-self: center; margin-left: 5px;">{{data.qntFavorites}} Favoritados</span>
		  </div>

		    <a id='btn-subscribe-event' class='btn-subscribe' @click="emit('openLogin', 'Para inscrever-se no evento, escolha sua melhor conta')" title='Inscreva-se no evento' data-bs-toggle='modal' href='#exampleModalToggleAceite' value='saibaMais->id_event' v-if="!page.props.auth.isLogged">{{title_btn}}</a>

		    <a id='btn-subscribe-event' class='btn-subscribe' @click="emit('openSubscribe', saibaMais.id_event)" title='Inscreva-se no evento' data-bs-toggle='modal' href='#exampleModalToggleAceite' value='saibaMais->id_event' v-if="page.props.auth.isLogged && title_btn == 'Inscrever'">{{title_btn}}</a>

		    <a id='btn-subscribe-event' :href="multiverseRoute" class='btn-subscribe' title='Inscreva-se no evento' value='saibaMais->id_event' v-if="title_btn == 'Participar'">{{title_btn}}</a>
	      </section>


	      <section class="container-description-location-datetime container">
		<div class="description-location-datetime row">
		  <div class="descritpion col">
		    <h3>Descrição</h3>
		    <p>
		      {{saibaMais.description}}
		    </p>
		  </div>
		  <div class="date-time-location col">
		    <div class="date-and-time">
			 <h3 class="date-n-time-title">Datas e Horários</h3>
			 <h6 v-if="saibaMais.eventactivities.lenght == 0">Este evento ainda não possui nenhuma atividade no momento.</h6>

			<div class="table-collapse" v-else>
			    <table class="table text-nowrap">
			      <thead class="thead-light">
				<tr>
				  <th>Atividade</th>
				  <th>Data</th>
				  <th>Horário</th>
				  <th>Status</th>
				  <th class="thInfo"></th>
				</tr>
			      </thead>
			      <tbody>
				  <tr v-for="dateEvents in saibaMais.eventactivities" style="border-bottom: 1px solid; border-color: inherit">
				    <td style="overflow-wrap: break-word" ><span v-html="dateEvents.name.replace(/.{10}/g, '$&<br />\n')"></span></td>
				    <td>
				      <span v-if="dateEvents.start_date_time_formated == dateEvents.end_date_time_formated">{{dateEvents.start_date_time_formated}}</span>
				      <span v-else>{{dateEvents.start_date_time_formated}} - {{dateEvents.end_date_time_formated}}</span>
				    </td>
				    <td>{{dateEvents.start_date_time_hours}} - {{dateEvents.end_date_time_hours}}</td>
				    <td style="display: flex;align-items: center; border: none;">
							<button v-if="dateNow < dateEvents.start_date_time_full && dateNow < dateEvents.end_date_time_full" class="btn-status" style="background: #f0ad4e; height: 30px;">Em breve</button>

							<button v-else-if="dateNow > dateEvents.start_date_time_full && dateNow < dateEvents.end_date_time_full" class="btn-status" style="background: #d9534f; height: 30px;">Em andamento</button>

							<button v-else class="btn-status" style="background: #808080; height: 30px;">Encerrado</button>
				    </td>
				  </tr>
			      </tbody>
			    </table>
			</div>
		    </div>
		  </div>
		</div>
	      </section>
    </div>



  <!-- MODAL SHARE FRIENDS -->
  <section class="modal fade" id="shareFriendsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header-share-friends">
          <h5 class="modal-title" id="exampleModalLabel">Compartilhar com amigos</h5>
          <button type="button" class="close" @click="shareModal.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul class="list-icon-midias-socials">
		<ShareNetwork
			network="facebook"
    			:url="currentRoute"
    			title="Check this new virtual event!">
				<i class="bi bi-facebook"></i>
		</ShareNetwork>
		<ShareNetwork
			network="linkedin"
    			:url="currentRoute"
    			title="Check this new virtual event!">
				<i class="bi bi-linkedin"></i>
		</ShareNetwork>
		<ShareNetwork
			network="twitter"
    			:url="currentRoute"
    			title="Check this new virtual event!">
				<i class="bi bi-twitter-x"></i>
		</ShareNetwork>
		<ShareNetwork
			network="whatsapp"
    			:url="currentRoute"
    			title="Check this new virtual event: ">
				<i class="bi bi-whatsapp"></i>
		</ShareNetwork>
          </ul>

          <div class="url-event">
            <input id="field_url" type="text" value="#">
            <i id="btn_copy_url" title="Copiar URL" class="bi bi-copy"></i>
          </div>

        </div>
      </div>
    </div>
  </section>

  <!-- MODAL TERMS FAVORITES-->
  <DropdownLogin />


  <!-- MODAL TERMS SUBSCRIBES -->
  <ModalSubscribe />
</template>

<style scoped>
	@media (width <= 600px) {
		.saibamais-container {
			width: 85% !important;
		}

		.saibamais-content {
			flex-direction: column !important;
		}

		.saibamais-img {
			width: 100% !important;
		}

		.saibamais-descritpion {
			width: 100% !important;
		}

		.container-share-and-participate {
			justify-content: center !important;
		}

		.container-share-and-participate > .icons-share {
			margin-bottom: 10px;
		}
	}

	.saibamais-container {
		display: flex;
		flex-direction: column;
		z-index: 2;
		position: absolute;
		top: 50%;
		left: 50%;
		height: 75%;
		width: 65%;
		background-color: #ffffff;
		border-radius: 5px;
		box-shadow: 0px 0px 10px 0px #00000099;
		overflow: scroll;
	}

	.saibamais-content {
		display: flex;
		justify-content: center;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		height: fit-content;
	}

	.saibamais-img {
		width: 50%;
		max-height: 410px;
	}
	.saibamais-img img {
		width: 100%;
		height: 100%;
	}

	.saibamais-descritpion {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 2rem 1rem;
		width: 50%;
		max-height: 410px;
		color: #ffff;
		background-color: #9C7FEF;
		border-radius: 0 5px 0px 0;
	}

	.title-event{
		font-weight: bold;
	}

	.icons-and-gratis {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.container-share-and-participate {
		width: 100%;
		height: fit-content;
		border-bottom: 1px solid #8686868e;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}


	.icons-share {
		display: flex;
		justify-content: center;
	}

	.share-friends, .btn-favorites {
		font-size: 18px;
		background: none;
		border-radius: 50%;
		border: 1px solid #7a7a7a;
		outline: none !important;
		width: 45px;
		height: 45px;
	}

	.btn-favorites {
		margin-left: 5px;
	}

	.qnt-favoritados {
		font-weight: bold;
		font-size: 14px;
		align-self: center;
		margin-left: 5px;
	}

	.btn-subscribe {
		border: none;
		background-color: #652CB3;
		color: #ffff !important;
		padding: 0.7rem 5rem;
		border-radius: 5px;
		box-shadow: 0px 10px 8px -6px #000000ad;
		outline: none;
		transition: all ease 0.3s;
	}

	.container-description-location-datetime {
		width: 100%;
		height: fit-content;
	}

	.description-location-datetime {
		display: flex;
		flex-direction: row;
	}

	.descritpion {
		width: 70%;
		padding: 2rem 3rem;
	}

	.descritpion p {
		text-align: justify;
	}

	.date-time-location {
		padding: 2rem 2rem;
	}

	.table-collapse {
		overflow-y: scroll;
		overflow-x: scroll;
		height: 250px !important;
	}

	.thead-light {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.thead-light tr th {
		padding: 15px;
		color: #0d0f11;
		background-color: #e9ecef;
		border-color: #dee2e6;
		font-weight: bold;
	}

	.date-time-location .btn-status {
		border: none;
		padding: 0.2rem 0.7rem;
		border-radius: 5px;
		font-weight: bold;
		color: #f1f1f1;
	}

	.date-n-time-title {
		width: fit-content;
	}

	.modal-content {
		border-radius: 13px !important;
	}

	.modal-header-share-friends {
		background-color: #E6E6E6;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem;
		border-radius: 13px !important;
	}

	.modal-body {
		text-align: center;
	}

	.modal-header-share-friends h5 {
		width: 100%;
	}

	.list-icon-midias-socials {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
		padding: 0;
		list-style: none;
	}

	.url-event {
		text-align: center;
	}

	.url-event input {
		width: 75%;
		height: 2.5rem;
		padding: 0 0.5rem;
		padding-right: 0.5rem;
		outline: none;
		text-overflow: ellipsis !important;
		padding-right: 2rem;
	}

	.bi.bi-copy {
		position: relative;
		right: 2rem;
		cursor: pointer;
	}

	.close {
		padding: 7px;
		background-color: transparent;
		border: 0;
	}

	.table td {
		padding: .75rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
	}

</style>
