<script setup>
	import { onMounted, computed, ref, defineEmits } from 'vue';
	import { usePage } from '@inertiajs/vue3'
	import { initializeApp } from "firebase/app";
	import { Dropdown, Modal } from 'bootstrap'
	import { getFirestore, collection, doc, getDoc, getDocs, addDoc, deleteDoc, updateDoc, onSnapshot, query, orderBy, limit } from "firebase/firestore";
	import axios from 'axios';

	const page = usePage()

	const emit = defineEmits([
		'openLogin'
	])

	let transitionObserver = new MutationObserver((mutations) => {
		for (let mutation of mutations) {
			let elementStyle = getComputedStyle(mutation.target)

			if (mutation.target.getAttribute('transitioned') == 1) {
				if (mutation.type == 'attributes') {
					mutation.target.setAttribute('transitioned', 0)

					if (elementStyle.getPropertyValue('--animation') == mutation.target.getAttribute('in-animation')) {
						mutation.target.style.setProperty('--start-display', mutation.target.getAttribute('in-display'))

					} else if (elementStyle.getPropertyValue('--animation') == mutation.target.getAttribute('out-animation')) {

						setTimeout(() => {
							mutation.target.style.setProperty('--start-display', mutation.target.getAttribute('out-display'))
						}, parseFloat(elementStyle.getPropertyValue('--animation-duration')) * 900)
					}

					setTimeout(() => {
						mutation.target.setAttribute('transitioned', 1)
					}, parseFloat(elementStyle.getPropertyValue('--animation-duration')) * 1000)
				}

			}
		}
	})

	let toggleElementAnimation = ({elementClass, inAnimation = "scale_in", outAnimation = "scale_out", inDisplay = "flex", outDisplay = "none"}) => {
		let element = document.querySelector(elementClass)
		let elementStyle = getComputedStyle(element)
		if (!element.hasAttribute('transitioned')) {
			element.setAttribute('transitioned', 1)
		}

		element.setAttribute('in-animation', inAnimation)
		element.setAttribute('out-animation', outAnimation)
		element.setAttribute('in-display', inDisplay)
		element.setAttribute('out-display', outDisplay)

		transitionObserver.observe(element, {attributes: true, attributeFilter: ["style"]})

		if (element.getAttribute('transitioned') == 1) {

			if (elementStyle.getPropertyValue('--animation') == '' && elementStyle.getPropertyValue('--start-display') == "none") {
				element.style.setProperty('--animation', inAnimation);
			} else if (elementStyle.getPropertyValue('--animation') == '' && elementStyle.getPropertyValue('--start-display') != 'none') {
				element.style.setProperty('--animation', outAnimation);
			} else {

				if (elementStyle.getPropertyValue('--animation') == inAnimation) {
					element.style.setProperty('--animation', '');
					element.style.setProperty('--animation', outAnimation);
				} else {
					element.style.setProperty('--animation', '');
					element.style.setProperty('--animation', inAnimation);
				}
			}

		}
	}

	let closeOpenActionMenu = () => {
		let messageList = document.querySelector('.message-list')
		let openActionMenus = [].slice.call(messageList.querySelectorAll('.action-menu[style*="--start-display: flex"]'))

		openActionMenus.forEach(actionMenu => {
			toggleActionMenu(actionMenu.id.replace('action-menu-', ""))
		})
	}

	let dateBRFull = new Intl.DateTimeFormat('pt-BR', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		fractionalSecondDigits: 3,
	})

	const firebaseConfig = {

      apiKey: page.props.google.firebase_api_key,

	  authDomain: "evchat-e7050.firebaseapp.com",

	  projectId: "evchat-e7050",

	  storageBucket: "evchat-e7050.appspot.com",

	  messagingSenderId: "589360393612",

	  appId: "1:589360393612:web:ac3927828dd32f6411baa9"

	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);


	let chatMessages = ref([]);
	let unreadMessages = ref(0);
	let bannedUsers = ref([]);
	let bannedWords = ref({});

	let currentDate = async () => {
		return await axios.get('http://worldtimeapi.org/api/timezone/Etc/UTC', {}, {}).then((response) => {
			return response.data.datetime.replace('T', ' ').replace('+', ' +')
		})
	}

	const handleMessageSnapshots = onSnapshot(query(collection(db, 'messages'), orderBy('created', 'desc'), limit(15)), (snapshot) => {
		snapshot.docChanges().forEach((change) => {

			if (change.type == 'added') {
				chatMessages.value.push({
					id: change.doc.id,
					id_user: change.doc.data().id_user,
					created: change.doc.data().created,
					username: change.doc.data().username,
					picture: change.doc.data().picture,
					sanitized: change.doc.data().sanitized,
					edited: change.doc.data().edited,
					message: change.doc.data().message,
				})

				chatMessages.value.sort((firstMessage, nextMessage) => {
					if (firstMessage.created > nextMessage.created) {
						return 1
					} else {
						return -1
					}
				})

				try {
					let chatDropdownEl = document.querySelector('.chat-dropdown')
					let messageList = document.querySelector('#message-list')
					messageList.addEventListener('DOMNodeInserted', function() {
						messageList.scrollTop = messageList.scrollHeight
					})

					if (getComputedStyle(chatDropdownEl).getPropertyValue('--start-display') == 'none') {
							unreadMessages.value++
					}
				} catch (err) {
					console.log(err)
				}
			}

			if (change.type == 'modified') {
				chatMessages.value.map(message => {
					if (message.id == change.doc.id) {
						message.message = change.doc.data().message
						message.sanitized = change.doc.data().sanitized
						message.edited = change.doc.data().edited
					}
				})
			}

			if (change.type == 'removed') {
				chatMessages.value = chatMessages.value.filter(message => {
					if (message.id != change.doc.id) {
						return true
					}
				})
			}
		})
	})

	const handleBannedUsersSnapshots = onSnapshot(collection(db, 'banned_users'), (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			if (change.type == 'added') {
				bannedUsers.value.push(change.doc.data().user_id)
			}
			if (change.type == 'removed') {
				bannedUsers.value = bannedUsers.value.filter(user_id => {
					if (user_id != change.doc.data().user_id) {
						return true
					}
				})

			}
		})
	})

	const handleBannedWordsSnapshots = onSnapshot(collection(db, 'banned_words'), (snapshot) => {
		snapshot.docChanges().forEach(change => {
			if (change.type == 'added') {
				bannedWords.value[change.doc.id] = change.doc.data().wordlist
			}

			if (change.type == 'modified') {
				bannedWords.value[change.doc.id] = bannedWords.value[change.doc.id].filter(word => {
					if (change.doc.data().wordlist.includes(word)) {
						return true
					}
				})

			}
		})
	})


	const sanitizeMessage = (message) => {
		let compressedMessage = message.replace(/\s+/g, '').toLowerCase();
		let isSafe = true

		Object.values(bannedWords.value).forEach(wordlist => {
			wordlist.forEach(word => {
				if (compressedMessage.includes(word)) {
					isSafe = false
				}
			})
		})

		return isSafe
	}

	const sanitizeMessageStyle = (message, sanitize) => {
		if (!sanitize) {
			return message
		} else {
			return message.split(' ').map((word) => {
				return '*'.repeat(word.length)
			}).join(' ')
		}
	}

	const toggleChat = (event) => {
		unreadMessages.value = 0
		let messageList = document.querySelector('#message-list')
		messageList.scrollTop = messageList.scrollHeight
		toggleElementAnimation({elementClass: '.dropdown-menu-chat'})
		toggleElementAnimation({elementClass: '.chat-bubble', inAnimation: 'wobble', outAnimation: 'wobble2', inDisplay: 'flex', outDisplay: 'flex'})
	}

	const toggleActionMenu = (message_id) => {
		let currentListItem = document.querySelector(`#li-${message_id}`)
		toggleElementAnimation({elementClass: `#message-container-${message_id}`, inAnimation: 'message_in', outAnimation: 'message_out'})
		toggleElementAnimation({elementClass: `#action-menu-${message_id}`, inAnimation: 'action_menu_in', outAnimation: 'action_menu_out'})
	}

	const toggleEditModal = async function(event) {

		let editMessageEl = document.querySelector('.edit-message')
		let editMessageBody = editMessageEl.querySelector('.your-message')
		let editForm = editMessageEl.querySelector('.edit-message-form')

		let messageDoc = doc(db, 'messages', event.target.value)
		let message = await getDoc(messageDoc)

		editMessageBody.querySelector('.message-text').innerText = message.data().message
		editMessageBody.querySelector('.message-username').innerText = message.data().username
		editMessageBody.querySelector('.profile-pic-img').src = message.data().picture

		editForm.querySelector('.edit-form').elements.message_text.value = message.data().message
		editForm.querySelector('.edit-form').elements.message_text.id = event.target.value

		toggleElementAnimation({elementClass: '.edit-message'})

		editForm.querySelector('.edit-form').elements.message_text.focus()
	}

	const addDocument = async function(event) {
		event.preventDefault()
		await currentDate().then(nowDate => {
			if (page.props.auth.isLogged && event.target.elements.message_text.value != "") {

				let isSafe = sanitizeMessage(event.target.elements.message_text.value)

				addDoc(collection(db, "messages"), {
					id_user: page.props.auth.user.id,
					created: nowDate,
					username: page.props.auth.user.nickname != null ? page.props.auth.user.nickname : page.props.auth.user.username,
					picture: page.props.auth.user.photo,
					sanitized: !isSafe,
					edited: false,
					message: event.target.elements.message_text.value,
				})

				event.target.elements.message_text.value = ""
			} else {
				emit('openLogin', 'Para enviar mensagens, escolha sua melhor conta')
			}

		})
	}

	const uptDocument = async function(event) {
		toggleActionMenu(event.target.elements.message_text.id)
		event.preventDefault()
		let updateMessage = doc(db, 'messages', event.target.elements.message_text.id)

		let isSafe = sanitizeMessage(event.target.elements.message_text.value)

		await updateDoc(updateMessage, {
			sanitized: !isSafe,
			edited: true,
			message: event.target.elements.message_text.value
		})

		toggleElementAnimation({elementClass: '.edit-message'})
	}

	const delDocument = async function(event) {
		let delMessage = doc(db, 'messages', event.target.value)
		await deleteDoc(delMessage)
	}

	const banUser = async function(event) {
		await addDoc(collection(db, "banned_users"), {
			user_id: Number(event.target.value),
			created: dateBRFull.format(new Date()),
		})
	}

	onMounted(() => {

	})

</script>

<template>

	<div class="chat-container">
		<div v-if="page.props.auth.isLogged" @click="toggleChat" class="chat-bubble" :data-count="unreadMessages < 100 ? unreadMessages : '99+'">
			<i class="bi bi-chat-left-fill"></i>

		</div>
		<div v-else @click="emit('openLogin', 'Para enviar e visualizar mensagens, escolha sua melhor conta')" class="chat-bubble notlogged" :data-count="unreadMessages < 100 ? unreadMessages : '99+'">
			<i class="bi bi-chat-left-fill"></i>
		</div>

	</div>
		  <ul class="dropdown-menu-chat chat-dropdown" @click="closeOpenActionMenu">
			<div class="chat-dropdown-header">
				<img src="https://app.geolivre.com.br/img/logo_plataforma.webp">
			</div>
			<div class="message-list" id="message-list" v-if="page.props.auth.isLogged">
			<li v-for="message in chatMessages" >

				<div v-if="!bannedUsers.includes(message.id_user) && message.id_user == page.props.auth.user.id" :id="`li-${message.id}`" class="your-message">
					<div class="message-container" :id="`message-container-${message.id}`">
						<div class='message-info your-message-info' name="message_info">
							<p class='message-username'>{{message.username}}</p>
							<p name="message_text" class='message-text' v-html="sanitizeMessageStyle(message.message, message.sanitized)"></p>
						</div>
						<div class='profile-pic' name="profile_pic">
							<img :src="message.picture" @click="toggleActionMenu(message.id)" :id="`img-${message.id}`"></img>
						</div>

					</div>

					<div class="action-menu hide" name="action_menu" :id="`action-menu-${message.id}`">
						<button v-if="message.id_user == page.props.auth.user.id || page.props.auth.user.user_group_id == 1" @click="delDocument" :value="message.id" class="ac-btn"><i class="bi bi-trash-fill"></i></button>
						<button v-if="message.id_user == page.props.auth.user.id" @click="toggleEditModal" :value="message.id" class="ac-btn"><i class="bi bi-pencil-fill"></i></button>
						<button v-if="page.props.auth.user.user_group_id == 1" @click="banUser" :value="message.id_user" class="ac-btn"><i class="bi bi-ban-fill" ></i></button>
					</div>
				</div>

				<div v-else-if="!bannedUsers.includes(message.id_user) && message.user_id != page.props.auth.user.id" :id="`li-${message.id}`" class="other-message">
					<div class='message-info other=message-info' name="message_info">
						<p class='message-username'>{{message.username}}</p>
						<p class='message-text' v-html="sanitizeMessageStyle(message.message, message.sanitized)"></p>
					</div>
					<div class='profile-pic' name="profile_pic">
						<img :src="message.picture" @click="toggleActionMenu(message.id)" :id="`img-${message.id}`"></img>
					</div>

					<div class="action-menu hide"  name="action_menu" :id="`action-menu-${message.id}`">
						<button v-if="message.id_user == page.props.auth.user.id || page.props.auth.user.user_group_id == 1" @click="delDocument" :value="message.id" class="ac-btn"><i class="bi bi-trash-fill"></i></button>
						<button v-if="page.props.auth.user.user_group_id == 1" @click="banUser" :value="message.id_user" class="ac-btn"><i class="bi bi-ban-fill" ></i></button>
					</div>
				</div>

				<div v-else>
					Usuario banido indefinidamente
				</div>
			</li>

			<li class="send-message-form">
				<form @submit="addDocument" v-if="!bannedUsers.includes(page.props.auth.user.id)">
					<input name="message_text" required></input><br />
					<button type="submit"><i class="bi bi-send"></i></button>
				</form>
				<div v-else>
					Banido meu parceiro
				</div>
			</li>
			</div>

			<div v-else class="message-list" id="message-list">
			</div>

			<div class="edit-message hide">
			      <div class="edit-message-header">
				<button type="button" @click="toggleElementAnimation({elementClass: '.edit-message'})" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			      </div>
			      <div class="edit-message-body">
					<div class="your-message">
							<div class='message-info your-message-info' name="message_info">
								<p class='message-username'></p>
								<p name="message_text" class='message-text edit-message-text'></p>
							</div>
							<div class='profile-pic' name="profile_pic">
								<img class="profile-pic-img"></img>
							</div>
					</div>
					<li class="edit-message-form">
						<form @submit="uptDocument" class="edit-form">
							<input name="message_text" id="" required></input><br />
							<button type="submit"><i class="bi bi-send"></i></button>
						</form>
					</li>
			      </div>
			</div>

		  </ul>



</template>

<style scoped>

	.notlogged::before {
		display: none !important;
	}


	.modal-dialog {
		width: 100%;
		height: 100%;
		margin: 0;
	}

	.modal-content {
		padding: 10px !important;
		z-index: 1100;
	}

	.modal-backdrop {
		z-index: 999;
	}

	.btn-close {
		position: absolute;
		right: 2%;
		top: 3%;
	}

	.chat-container {
		width: fit-content;
		height: fit-content;
		position: absolute;
		left: 30px;
		bottom: 30px;
		padding: 0;
		margin: 0;
	}

	.chat-bubble {
		--animation: ;
		--animation-duration: 0.5s;
		--animation-iteration-count: 1;
		--transition: 1s;
		--start-display: flex;
		display: var(--start-display);
		animation-name: var(--animation);
		animation-duration: var(--animation-duration);
		animation-iteration-count: var(--animation-iteration-count);
		transition: var(--transition);
		height: 60px;
		width: 60px;
		border-radius: 50%;
		background-color: #928dcf;
		z-index: 999;
		cursor: pointer;
		justify-content: center;
 	 	align-content: center;
 	 	align-items: center;
	}

	.chat-bubble::before {
		content: attr(data-count);
		background-color: red;
		font-size: 12px;
		font-family: sans-serif;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 1.7em;
		min-height: 1.7em;
		position: absolute;
		top: 0px;
		right: 0px;
		border-radius: 50%;
	}

	.chat-bubble i {
		pointer-events: none;
	}

	.chat-bubble i::before {
		color: white;
		transform: scale(1.5);
		pointer-events: none;
	}

	.chat-dropdown {
		z-index: 1000;
		position: absolute;
        bottom: 100px;
        left: 0px;
		width: 100%;
		height: fit-content;
		padding: 10px;
		margin: 0;
	}

	.dropdown-menu-chat {
		--animation: ;
		--animation-duration: 0.7s;
		--animation-iteration-count: 1;
		--transition: 1s;
		--start-display: none;
		display: var(--start-display);
		animation-name: var(--animation);
		animation-duration: var(--animation-duration);
		animation-iteration-count: var(--animation-iteration-count);
		transition: var(--transition);
		list-style-type: none;
		padding: 10px;
		max-width: 500px;
		height: 50vh;
		overflow-y: scroll;
		overflow: visible;
		background-color: white;
		border-radius: 20px;
		box-shadow: 0px 5px 30px #8e52c8;
		flex-direction: column;
	}

	.dropdown-menu-chat li {
		margin-top: 5px;
		height: fit-content;
	}

	.message-list {
		height: 70%;
	 	overflow-y: scroll;
		padding: 8px;
	}

	.your-message {
		overflow-x: hidden;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #6862b3;
		border-radius: 10px;
		padding: 5px 10px 5px 10px;
		height: fit-content;
		margin-left: 40%;
	}

	.other-message {
		overflow-x: hidden;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		background-color: #928dcf;
		border-radius: 10px;
		padding: 5px 10px 5px 10px;
		height: fit-content;
		width: 60%;
	}

	.profile-pic {
		--animation: ;
		--animation-duration: 0.7s;
		--animation-iteration-count: 1;
		--transition: 1s;
		--start-display: initial;
		display: var(--start-display);
		width: 60px;
		height: 60px;
        cursor: pointer;
	}

	.profile-pic img {
		height: 60px;
		width: 60px;
		border-radius: 50%;
		margin-right: 5px;
	}

	.message-info {
		--animation: ;
		--animation-duration: 0.7s;
		--animation-iteration-count: 1;
		--transition: 1s;
		--start-display: initial;
		display: var(--start-display);
		color: white;
		width: 75%;
		height: 100%;
	}

	.other-message-info {
		text-align: start;
	}

	.your-message-info {
		text-align: end;
	}

	.message-username {
		padding-left: 10px;
		padding-right: 10px;
	}

	.message-text {
		padding: 10px;
		word-break: break-word;
	}

	.edit-message-text {
		margin-bottom: 60px;
		margin-top: 30px;
	}

	.send-message-form {
		position: absolute;
		bottom: 5%;
		right: 2%;
		height: 10% !important;
		width: 95%;
	}

	.edit-message-form {
		position: absolute;
		bottom: 5%;
		height: 15% !important;
		width: 96%;
	}

	.send-message-form form, .edit-message-form form {
		display: flex;
		height: 100%;
	}

	.send-message-form form input, .edit-message-form form input {
		border-radius: 10px;
		width: 100%;
		height: 100%;
		padding-right: 10%;
		padding-left: 2%;
	}

	.send-message-form form button, .edit-message-form form button {
		position: absolute;
		background: transparent;
	  	border: none;
	  	right: 0;
		height: 100%;
		width: 10%;
	}

	.send-message-form form button i::before, .edit-message-formbutton i::before {
		transform: scale(1.5);
	}

	.edit-message-form {
		display: block;
	}

	.action-menu {
		--animation: ;
		--animation-duration: 0.7s;
		--animation-iteration-count: 1;
		--transition: 1s;
		--start-display: none;
		animation-name: var(--animation);
		animation-duration: var(--animation-duration);
		animation-iteration-count: var(--animation-iteration-count);
		transition: var(--transition);
		display: var(--start-display);
		width: 100%;
		height: 100px;
		justify-content: space-evenly;
	}

	.ac-btn {
		background: transparent;
		border: none;
		color: white;
		margin: 10px
	}

	.ac-btn i {
		pointer-events: none;
	}

	.ac-btn i::before {
		transform: scale(3.0);
		pointer-events: none;
	}

	.bi-send::before {
		color: black !important;
	}

	.edit-message {
		--animation: ;
		--animation-duration: 0.7s;
		--animation-iteration-count: 1;
		--transition: 1s;
		--start-display: none;
		display: var(--start-display);
		animation-name: var(--animation);
		animation-duration: var(--animation-duration);
		animation-iteration-count: var(--animation-iteration-count);
		transition: var(--transition);
		position: absolute;
		top: 0%;
		right: 0%;
		padding: 10px;
		background-color: white;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		flex-direction: column;
	}

	.edit-message-header {
		height: 30px;
		margin-bottom: 10px;
	}

	.chat-dropdown-header {
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}

	.chat-dropdown-header img {
		height: 30px;
	}

	.message-container {
		--animation: ;
		--animation-duration: 0.7s;
		--animation-iteration-count: 1;
		--transition: 1s;
		--start-display: flex;
		display: var(--start-display);
		animation-name: var(--animation);
		animation-duration: var(--animation-duration);
		animation-iteration-count: var(--animation-iteration-count);
		transition: var(--transition);
		width: 100%;
		display: var(--start-display);
		justify-content: inherit;
		align-items: inherit;
	}
</style>
