<script setup>
	import { usePage } from '@inertiajs/vue3'
	import { ref, onMounted, defineEmits } from 'vue';
	import { Popover, Modal } from 'bootstrap';

	const page = usePage()

	const props = defineProps({
		event: Object,
		array_btns: Object,
	})

	const emit = defineEmits([
		'openLogin',
		'openSubscribe'
	])

	const saibaMaisRoute = route('ev.events.saibamais', {id_event: props.event.id_event})
	const multiverseRedirectRoute = route('ev.multiverse.view', {id: props.event.multiverse_id})

	let user_office = ''
	let user_linkedin = ''
	let user_phone  = ''
	let user_company = ''

	if (page.props.auth.user != null) {
		user_office = page.props.auth.user.cargo == null ? '' : page.props.auth.user.cargo
		user_linkedin = page.props.auth.user.linkedin == null ? '' : page.props.auth.user.linkedin
		user_phone  = page.props.auth.user.user_detail == null ? '' : page.props.auth.user.user_detail
		user_company = page.props.auth.user.empresa == null ? '' : page.props.auth.user.empresa
	}

	let hide_or_unhide = null

	if (user_linkedin == '' && user_office == '' && user_phone == '' && user_company == '') {
	    hide_or_unhide = 'block';
	} else {
	    hide_or_unhide = 'none';
	}

        let bannerImgLocalPath = props.event.image_hash;
        let bannerImgFilteredPath = [];
        let filter_threshold_active = 0;

        if (bannerImgLocalPath != null) {
            bannerImgLocalPath.split('/')
			.forEach((URLseg) => {
                if (URLseg == "webroot") {
                    filter_threshold_active = 1;
                }

                if (filter_threshold_active == 1) {
                    bannerImgFilteredPath.push(URLseg);
                }

			})
        }

        bannerImgFilteredPath = bannerImgFilteredPath.join("/");

        let fullImagePath = `${page.props.pathDomain}/` + bannerImgFilteredPath;

	let modalLogin = null;
	let modalSubscribe = null;

</script>

<template>
	<div class="items-events" :id="'event-' + props.event.id_event">
		  <div class="camada-degrade">
		    <div class="container-bnts">
		      <div>
				<a class="saibamais-link" :href="saibaMaisRoute">
					<i class="bi bi-info-circle-fill"></i>
				</a>
		      </div>
		    </div>
		    <div class="img-card-container">
		   	<div style="display: block; height: 100%; width: 100%;" v-if="!page.props.auth.isLogged">
				<a class="login-dropdown" @click="emit('openLogin')" style="display: block; height: 100%; width: 100%;">
				      <img class="img-card" runat="server" :src="renameBanner" alt="">
				</a>
			</div>
			<a @click="emit('openSubscribe', props.event.id_event)" v-if="page.props.auth.isLogged && !array_btns.array_btns_subscribed.includes(props.event.id_event)">
			      <img class="img-card" runat="server" :src="fullImagePath" alt="">
			</a>
			<a :href="multiverseRedirectRoute" v-else-if="page.props.auth.isLogged && array_btns.array_btns_subscribed.includes(props.event.id_event)">
			      <img class="img-card" runat="server" :src="fullImagePath" alt="">
			</a>
		    </div>

				<div class="card-information-div-wrapper">
				  <div class="card-information-div" v-if="!page.props.auth.isLogged">
					<a class="login-dropdown" @click="emit('openLogin')" data-bs-target="#loginModal">
						  <h5 class='card-title' style="color: white;">{{props.event.event_name}}</h5>

						  <div class="container-description" style="color: white;">
							{{props.event.description}}
						  </div>
					</a>

				  </div>
				  <div class="card-information-div" v-else-if="page.props.auth.isLogged && !array_btns.array_btns_subscribed.includes(props.event.id_event)">
					<a class="login-dropdown" @click="emit('openSubscribe')" data-bs-target="#loginModal">
						  <h5 class='card-title' style="color: white;">{{props.event.event_name}}</h5>

						  <div class="container-description" style="color: white;">
							{{props.event.description}}
						  </div>
					</a>

				  </div>
				  <div class="card-information-div" v-else-if="page.props.auth.isLogged && array_btns.array_btns_subscribed.includes(props.event.id_event)">
					<a class="login-dropdown" :href="multiverseRedirectRoute" data-bs-target="#loginModal">
						  <h5 class='card-title' style="color: white;">{{props.event.event_name}}</h5>

						  <div class="container-description" style="color: white;">
							{{props.event.description}}
						  </div>
					</a>

				  </div>
				</div>
		  </div>
		</div>
</template>

<style scoped>
	@media (orientation: portrait) or (width < 600px) {
		.items-events {
			height: 60% !important;
		}

		.items-events:hover, .items-events:active {
			height: 65% !important;
		}

		.container-description {
			height: 70px;
			overflow: clip;
			transition: all ease 1s;
		}

		.items-events:hover .container-description, .items-events:active .container-description {
			max-height: 200px;
			height: fit-content;
			overflow: scroll;
		}
	}

	.items-events {
		width: 95%;
		height: 80%;
		border: none;
		cursor: pointer;
		text-align: left;
		border-radius: 10px;
		transform: scale(0.95);
		box-shadow: 0px 0px 34px -11px #0000006b;
		transition: all ease 0.3s;
		z-index: 75;
		font-family: 'Fredoka', sans-serif;
	}


	.items-events:hover {
		width: 100%;
		height: 85%;
	}

	.items-events:hover .container-bnts, .items-events:active .container-bnts {
		opacity: 1;
		color: white;
	}

	.camada-degrade {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.7791491596638656) 0%, rgba(0, 0, 0, 1) 70%);
		z-index: 100;
	}

	.container-bnts {
		opacity: 0;
		transform: scale(2.50) !important;
		transition: all ease 0.3s;
		top: 5% !important;
		display: flex;
		position: absolute;
		right: 2%;
		gap: 10px;
		padding: 0 1rem;
		z-index: 100;
	}

	.img-card-container {
		width: 100% !important;
		height: 100% !important;
		border: none;
		border-radius: 7px;
	}

	.img-card {
		width: 100%;
		height: 100%;
		border-radius: 7px;
	}

	.card-information-div-wrapper {
		height: fit-content;
		width: 100%;
		color: white;
		padding: 0rem 1rem 1rem 1rem;
		border: none;
		background-image: linear-gradient(#00000000, #000000);
		transition: step-start 2s;
		z-index: 2;
		border-radius: 0 0 7px 7px;
		position: absolute;
		bottom: 0px;
	}

	.card-title {
		margin-top: 0rem;
		font-size: 25px;
		font-weight: bold;
		display: block;
		float: inherit;
		margin-bottom: 0.5rem;
		font-weight: 600;
	}

</style>
