<script setup>
	import { usePage, router } from '@inertiajs/vue3';
	import { onMounted, ref } from "vue";
	import axios from 'axios';

	const page = usePage()
	const yesConfirm = function() {
		page.props.message_bag.push({'type': 'info', 'text': 'Salvando alterações...'})

		setTimeout(() => {
			page.props.message_bag.push({'type': 'success', 'text': 'Alterações salvas com sucesso!'})

			let elementList = document.querySelector('#info-form').elements

			axios.post(route('ev.user.edit-profile'), {}, {params: {
					nickname: elementList.nickname.value,
					first_name: elementList.first_name.value,
					last_name: elementList.last_name.value,
					cellphone: elementList.cellphone.value,
					role: elementList.role.value,
					company: elementList.company.value,
					gender: elementList.gender.options[elementList.gender.selectedIndex].value,
					linkedin: elementList.linkedin.value,
				}
			}).then((response) => {
				router.reload({only: ['auth', 'user_details']})
				window.history.back()
			})

		}, 750)
	}

	const noConfirm = () => {
		window.history.back()
	}

</script>

<template>

	<div id="confirm-form-modal" class="modal" tabindex="-1">
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-body">
		<div class="confirm-container">
			<p>Salvar alterações?</p>
			<div>
				<button class="yes-confirm" @click="yesConfirm">Sim</button><button class="no-confirm" @click="noConfirm">Não</button>
			</div>
		</div>
	      </div>
	    </div>
	  </div>
	</div>

</template>

<style scoped>

	#confirm-form-modal {
		animation-name: fade_up;
		animation-duration: 0.5s;
		animation-iteration-count: 1;
		transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);

	}

	.modal-content {
		color: white;
		background-color: #b3b9f1 !important;
	}

	.confirm-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.yes-confirm {
		border-radius: 5px;
		border: none;
		background-color: #652bb3;
		color: white;
		padding: 10px;
		width: 100px;
		margin: 0px 5px 0px 5px;
		font-size: 20px;
	}

	.no-confirm {
		border-radius: 5px;
		border: none;
		background-color: gray;
		color: white;
		padding: 10px;
		width: 100px;
		margin: 0px 5px 0px 5px;
		font-size: 20px;
	}

	@keyframes fade_up {
		0% {
			opacity: 0;
			transform: translateY(-100px) scale(0.5);
		}
	}
</style>
